import { useCallback, useEffect, useState } from 'react'
import { differenceInMilliseconds } from 'date-fns'

const REVALIDATE_INTERVAL = 60000 // one minute in milliseconds

interface UseShowingIntervalParams {
  id: string
  period: number
}

export function useShowingInterval({ id, period }: UseShowingIntervalParams) {
  const [isShown, setIsShown] = useState(false)
  const [isShownRevalidationInProgress, setIsShownRevalidationInProgress] = useState(false)

  function show() {
    setIsShownRevalidationInProgress(true)
  }

  function stopShowing() {
    setIsShown(false)
    setIsShownRevalidationInProgress(false)
    window.localStorage.removeItem(id)
  }

  function showLater() {
    setIsShown(false)
    window.localStorage.setItem(id, new Date().toISOString())
  }

  const revalidate = useCallback(() => {
    const lastDismissDateISOString = window.localStorage.getItem(id)

    if (!lastDismissDateISOString) {
      setIsShown(true)
      return
    }

    const lastDismissDate = new Date(lastDismissDateISOString)

    const diffInMilliseconds = differenceInMilliseconds(new Date(), lastDismissDate)

    if (diffInMilliseconds >= period) {
      setIsShown(true)
    }
  }, [setIsShown])

  useEffect(() => {
    if (!isShownRevalidationInProgress) {
      return
    }

    revalidate()
    const timer = window.setInterval(revalidate, REVALIDATE_INTERVAL)

    return () => {
      window.clearInterval(timer)
    }
  }, [isShownRevalidationInProgress, revalidate])

  return {
    isShown,
    show,
    stopShowing,
    showLater,
  }
}
