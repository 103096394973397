import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST,
  paramsSerializer: {
    indexes: null,
  },
})

export default instance
