import { UserPhoneNumber, UserPhoneNumberConsent } from 'api/dto'
import { axios } from 'api/lib'
import { create } from 'zustand'
import { SmsReceivingConsentForm } from './SmsReceivingConsentForm'

export interface SmsReceivingConsentDialogStore {
  readonly state:
    | 'sms-receiving-consent-dialog'
    | 'sms-receiving-consent-dialog/loading'
    | 'sms-receiving-consent-dialog/loaded'
    | 'sms-receiving-consent-dialog/submitting'
    | 'sms-receiving-consent-dialog/submitted'
    | 'sms-receiving-consent-dialog/dismissed'
    | 'sms-receiving-consent-dialog/error'

  readonly userPhoneNumber?: UserPhoneNumber
  readonly error?: string

  load(): Promise<void>
  submit(formData: SmsReceivingConsentForm): Promise<void>
}

export const useSmsReceivingConsentDialogStore = create<SmsReceivingConsentDialogStore>()(
  (set) => ({
    state: 'sms-receiving-consent-dialog',

    load: async () => {
      set({ state: 'sms-receiving-consent-dialog/loading', error: undefined })
      try {
        const { data: userPhoneNumber } = await axios.get<UserPhoneNumber>('/api/user-phone-number')
        set({ userPhoneNumber, state: 'sms-receiving-consent-dialog/loaded' })
      } catch (error: any) {
        set({
          state: 'sms-receiving-consent-dialog/error',
          error: error.message || 'Unable to load user phone number.',
        })
      }
    },

    submit: async (formData: SmsReceivingConsentForm) => {
      set({ state: 'sms-receiving-consent-dialog/submitting', error: undefined })
      try {
        const { data: userPhoneNumber } = await axios.post<UserPhoneNumber>(
          '/api/user-phone-number',
          formData,
        )
        set({
          userPhoneNumber,
          state:
            // get back to loaded stet to not show success step
            formData.consent === UserPhoneNumberConsent.CONSENTED
              ? 'sms-receiving-consent-dialog/submitted'
              : 'sms-receiving-consent-dialog/dismissed',
        })
      } catch (error: any) {
        set({
          state: 'sms-receiving-consent-dialog/error',
          error: error.message || 'Unable to submit user phone number.',
        })
      }
    },
  }),
)
