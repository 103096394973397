import { FC } from 'react'
import GroupList from './GroupList'

const GroupsHome: FC = () => {
  return (
    <>
      <h1 className="text-deep-teal-500 font-brand text-3xl font-medium lining-nums">Groups</h1>

      <h2 className="text-deep-teal-900 mb-4 mt-6 text-base font-bold">My groups</h2>
      <GroupList
        params={{ filter: 'member', isDeleted: false, sortBy: 'lastCreated' }}
        showGroupAccessBadge={true}
        emptyStateText="Your groups will appear here with information about their membership and updates as soon as you join some"
      />

      <h2 className="text-deep-teal-900 mb-4 mt-6 text-base font-bold">All public groups</h2>
      <GroupList
        params={{ filter: 'notMember', isDeleted: false, sortBy: 'lastCreated' }}
        showGroupAccessBadge={true}
        emptyStateText="You have joined all currently available groups. If you'd like to suggest a new group, please email membership@meetperry.com"
      />
    </>
  )
}

export default GroupsHome
