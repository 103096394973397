import { ComponentProps, FC } from 'react'
import { Event } from 'api/src/cms'
import { Carousel, EventCardLoading, CarouselEventCard } from 'ui/components/content'
import { EventsEmptyState } from './EventsEmptyState'

type EventsCarouselProps = Pick<ComponentProps<'div'>, 'className'> & {
  events: Event[]
  isLoading: boolean
  slidesToShow?: number
}

export const EventsCarousel: FC<EventsCarouselProps> = ({
  events,
  isLoading,
  slidesToShow,
  className,
}) => {
  if (isLoading) {
    return <EventCardLoading className={className} />
  }

  if (!events.length) {
    return <EventsEmptyState className={className} />
  }

  return (
    <Carousel className={className} slidesToShow={slidesToShow}>
      {events.map((event) => (
        <CarouselEventCard event={event} key={event.id} />
      ))}
    </Carousel>
  )
}