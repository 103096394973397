import { ComponentProps, FC } from 'react'
import Image from 'next/image'
import { Announcement } from 'api/cms'
import clsx from 'clsx'
import { Button } from 'ui/src/components/forms'

interface AnnouncementCardProps extends ComponentProps<'div'> {
  readonly announcement: Announcement
}

export const AnnouncementCard: FC<AnnouncementCardProps> = ({ className, announcement }) => {
  return (
    <div className={clsx('flex cursor-pointer flex-col gap-4', className)}>
      {/* Desktop layout */}
      <div className="hidden gap-4 sm:flex">
        <ImageWrapper className="h-[90px] w-[120px] shrink-0" src={announcement.image?.url} />
        <div className="space-y-2">
          <div className="space-y-1">
            <div className="text-deep-teal-900 text-base font-semibold leading-5">
              {announcement.title}
            </div>
            <div className="text-deep-teal-400 text-xs font-normal leading-4">
              {announcement.body}
            </div>
          </div>
          <a hidden href={`${announcement.url}`} className="hidden" />
          <Button size="xs" uiType="secondary" href={`${announcement.url}`} className="leading-3">
            Learn more
          </Button>
        </div>
      </div>
      {/* Mobile layout */}
      <div className={clsx('gap-4 sm:hidden', className)}>
        <div className="space-y-4">
          <div className="space-y-3">
            <div className="flex space-x-4">
              <ImageWrapper className="h-[40px] w-[40px] shrink-0" src={announcement.image?.url} />
              <div className="text-deep-teal-900 text-base font-semibold leading-5">
                {announcement.title}
              </div>
            </div>
            <div className="text-deep-teal-400 text-sm font-normal leading-4">
              {announcement.body}
            </div>
          </div>

          <Button size="xs" uiType="secondary" href={`${announcement.url}`} className="leading-3">
            Learn more
          </Button>
        </div>
      </div>
    </div>
  )
}

interface ImageProps extends ComponentProps<'div'> {
  src: string
}

const ImageWrapper: FC<ImageProps> = ({ className, src, ...props }) => {
  return (
    <div className={clsx('relative flex items-center justify-center', className)} {...props}>
      <Image
        src={src}
        className="h-full w-full shrink-0 rounded object-cover object-center"
        fill={true}
        alt="announcement image"
      />
    </div>
  )
}
