import { ComponentProps, FC } from 'react'
import { Popover } from '@headlessui/react'
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { PlainNav, NavigationItem } from './Nav'

interface PopoverMenuInterface {
  navigation: NavigationItem[]
  children: JSX.Element
}

export const PopoverMenu: FC<PopoverMenuInterface> = ({ navigation, children }) => {
  return (
    <>
      <PlainNav navigation={navigation} />
      <div className="border-deep-teal-400 border-t pt-4 pb-3">{children}</div>
    </>
  )
}

interface PopoverMenuButtonProps extends ComponentProps<'div'> {
  open: boolean
}

export const PopoverMenuButton: FC<PopoverMenuButtonProps> = ({ className, open, ...props }) => {
  return (
    <div className={clsx('flex items-center', className)} {...props}>
      <Popover.Button className="text-deep-teal-100 hover:bg-deep-teal-600 -mx-2 inline-flex items-center justify-center rounded-md p-2 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
        <>
          <span className="sr-only">Open menu</span>
          <XMarkIcon className={clsx('h-6 w-6', open ? 'block' : 'hidden')} aria-hidden="true" />
          <Bars3Icon className={clsx('h-6 w-6', open ? 'hidden' : 'block')} aria-hidden="true" />
        </>
      </Popover.Button>
    </div>
  )
}
