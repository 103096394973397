import { ComponentProps, FC } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import { MinusCircleIcon } from '@heroicons/react/24/outline'
import { InvestmentOpportunityWithTotalIoi } from 'api/dto'
import clsx from 'clsx'
import { Link } from 'ui/components/navigation'
import { formatMoneyShort } from 'ui/src/lib/formatters/moneyFormatter'
import { MarkdownProse } from '../MarkdownProse'

export interface InvestmentCardProps extends ComponentProps<'div'> {
  readonly investment: Pick<
    InvestmentOpportunityWithTotalIoi,
    'id' | 'totalIOIs' | 'state' | 'title' | 'fundType' | 'insight' | 'sponsor'
  >
}

export const InvestmentCard: FC<InvestmentCardProps> = ({ className, investment, ...props }) => {
  return (
    <Link href={`/investments/${investment.id}`}>
      <div
        className={clsx(
          'border-deep-teal-100 flex h-full flex-col justify-center gap-3 rounded-lg border px-4 py-6',
          className,
        )}
        {...props}
      >
        <div className="flex justify-between">
          {investment.totalIOIs && (
            <IOBadge className="text-[#426F8D]">
              {`${formatMoneyShort(investment.totalIOIs)} in IOIs`}
            </IOBadge>
          )}
          <State className="shrink-0 grow-0" state={investment.state} />
        </div>
        <div className="flex items-start justify-between gap-3">
          <img
            className="h-11 w-11 shrink-0 grow-0 object-contain"
            src={investment.sponsor?.avatar?.url}
          />

          <div className="flex-1 space-y-1">
            <div className="text-deep-teal-500 text-base font-semibold leading-5">
              {investment.title}
            </div>
            {investment.fundType && (
              <div className="text-deep-teal-400 text-xs ">{investment.fundType}</div>
            )}
          </div>
        </div>

        {investment.insight && <Insight className="self-start" insight={investment.insight} />}
      </div>
    </Link>
  )
}

export interface StateProps extends ComponentProps<'div'> {
  readonly state?: string
}

export const State: FC<StateProps> = ({ state, className, ...props }) => {
  return (
    <div className={clsx('flex items-center gap-1', className)} {...props}>
      {state === 'new' ? (
        <>
          <StarIcon data-testid="star-icon" className="text-navy-400 h-3 w-3" />
          <span className="text-navy-400 text-xs font-semibold">NEW</span>
        </>
      ) : state === 'closed' ? (
        <>
          <MinusCircleIcon data-testid="minus-circle-icon" className="text-taupe-700 h-3 w-3" />
          <span className="text-taupe-700 text-xs font-semibold">CLOSED</span>
        </>
      ) : (
        <>{/* Intentionally left empty */}</>
      )}
    </div>
  )
}

interface InsightProps extends ComponentProps<'div'> {
  readonly insight?: string
}

export const Insight: FC<InsightProps> = ({ insight, className, ...props }) => {
  return (
    <MarkdownProse
      className={clsx(
        'text-deep-teal-500 bg-deep-teal-50 inline rounded px-2.5 py-0.5 text-xs font-semibold',
        className,
      )}
      markdown={insight}
      {...props}
    />
  )
}

export const IOBadge: FC<ComponentProps<'div'>> = ({ children, className, ...props }) => {
  return (
    <div
      className={clsx(
        'border-l-2 border-l-orange-500 pl-2 text-right text-xs font-semibold leading-4',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
