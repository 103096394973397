import { SuccessCard } from 'ui/components/content/SuccessCard'
import { SmsReceivingConsentForm } from './SmsReceivingConsentForm'
import { useSmsReceivingConsentDialogStore } from './useSmsReceivingConsentDialogStore'

interface SmsReceivingConsentDialogProps {
  onFinish: () => void
}

export function SmsReceivingConsentDialog({ onFinish }: SmsReceivingConsentDialogProps) {
  const { state } = useSmsReceivingConsentDialogStore()

  switch (state) {
    case 'sms-receiving-consent-dialog/loaded':
    case 'sms-receiving-consent-dialog/submitting':
    case 'sms-receiving-consent-dialog/error':
      return <SmsReceivingConsentForm className="h-full grow" onDismiss={onFinish} />
    case 'sms-receiving-consent-dialog/submitted':
      return (
        <SuccessCard
          title={
            <>
              Thank you!
              <br />
              We have recorded your submission
            </>
          }
          dismissLabel="Go back"
          onDismiss={onFinish}
        />
      )
    default:
      return null
  }
}
