import { PropsWithChildren } from 'react'
import { RoutingContextValue, RoutingContext } from './RoutingContext'

interface RoutingContextProviderProps<T extends HTMLElement> extends PropsWithChildren {
  value: RoutingContextValue<T>
}

export function RoutingContextProvider<T extends HTMLElement>({
  children,
  value,
}: RoutingContextProviderProps<T>) {
  return <RoutingContext.Provider value={value}>{children}</RoutingContext.Provider>
}
