import { ComponentProps } from 'react'
import ReactPhoneNumberInput from 'react-phone-number-input'
import { cn } from 'ui/lib'
import 'react-phone-number-input/style.css'

export interface PhoneInputProps
  extends Omit<
    ComponentProps<typeof ReactPhoneNumberInput>,
    | 'defaultCountry'
    | 'countryOptionsOrder'
    | 'international'
    | 'countryCallingCodeEditable'
    | 'numberInputProps'
    | 'value'
    | 'onChange'
  > {
  value?: string
  onChange: (value: string) => void
  error?: boolean
  disabled?: boolean
}

export function PhoneInput({
  className,
  value,
  error = false,
  disabled = false,
  onChange,
  ...props
}: PhoneInputProps) {
  const inputClassName = cn(
    'block w-full px-3 py-2', // Alignment
    'border-taupe-600 hover:border-taupe-700 rounded-md shadow-sm focus:ring-0 ', // Border
    'text-deep-teal-900 text-sm leading-5 ', // Text
    error
      ? [
          'appearance-none bg-none', // suppressing native element styling (select, number, etc.)
          'border-pure-red hover:border-pure-red focus:border-pure-red border',
        ]
      : 'focus:border-navy-500',
    { 'cursor-not-allowed': disabled },
  )

  return (
    <ReactPhoneNumberInput
      className={cn('mt-1 flex gap-2', className)}
      defaultCountry="US"
      countryOptionsOrder={['US', 'GB', 'AE', 'ES']}
      international
      countryCallingCodeEditable
      numberInputProps={{
        className: inputClassName,
      }}
      value={value}
      onChange={(value) => {
        onChange(value || '')
      }}
      {...props}
    />
  )
}
