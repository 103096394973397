import { SWRConfiguration, SWRResponse } from 'swr'
import { SWRInfiniteConfiguration } from 'swr/infinite'
import { InfiniteResponse, useAmityApi, useAmityApiInfinite } from './useAmityApi'
import {
  AmityCommunity,
  AmityMember,
  AmityUser,
  GetGroupsResult,
  GetGroupMembersResult,
  QueryGroupMembersParams,
  QueryGroupsParams,
} from '../types'

export function useGroup(groupId: string, swrConfig?: SWRConfiguration): UseGroupsResult {
  const response = useAmityApi<GetGroupsResult>(`/v3/communities/${groupId}`, swrConfig)
  const group = response?.data?.communities[0]
  return { group, ...response }
}

export function useGroups(
  params?: QueryGroupsParams,
  swrConfig?: SWRInfiniteConfiguration,
): UseGroupsInfiniteResult {
  const response = useAmityApiInfinite<GetGroupsResult>('/v3/communities', swrConfig, { params })
  return { ...response, flatData: response.data?.flatMap((page) => page.communities) || [] }
}

export function useGroupMembers(
  { groupId, ...restParams }: QueryGroupMembersParams,
  swrConfig?: SWRInfiniteConfiguration,
): UseGroupMembersInfiniteResult {
  const response = useAmityApiInfinite<GetGroupMembersResult>(
    `/v3/communities/${groupId}/users`,
    swrConfig,
    { params: restParams },
  )

  return {
    ...response,
    flatUsers: response.data?.flatMap((page) => page.users) || [],
    flatCommunityUsers: response.data?.flatMap((page) => page.communityUsers) || [],
  }
}

export interface UseGroupsResult extends SWRResponse<GetGroupsResult> {
  group?: AmityCommunity
}

export interface UseGroupsInfiniteResult extends InfiniteResponse<GetGroupsResult> {
  flatData: AmityCommunity[]
}

export interface UseGroupMembersInfiniteResult extends InfiniteResponse<GetGroupMembersResult> {
  flatUsers?: AmityUser[]
  flatCommunityUsers?: AmityMember[]
}
