import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const useDependentState = <T>(externalValue: T): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState(externalValue)

  useEffect(() => {
    if (externalValue !== value) {
      setValue(externalValue)
    }
  }, [externalValue])

  return [value, setValue]
}

export default useDependentState
