import { FC, HTMLAttributes } from 'react'
import Head from 'next/head'
import clsx from 'clsx'
import { SignupHelpHint } from './SignupHelpHint'

const SignupLayout: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  title,
  ...props
}) => {
  return (
    <>
      <Head>{title && <title>{title}</title>}</Head>

      <style jsx global>
        {`
          body {
            background-color: #0a373c !important;
          }
        `}
      </style>

      <div id="signupContent" className={clsx('bg-deep-teal-500', className)} {...props}>
        <div className="fixed inset-0 bg-[url(/images/bg-texture-swirls.svg)] bg-fixed p-12" />
        <div className="relative p-4 md:p-24">
          {children}
          <SignupHelpHint className="mt-4" />
        </div>
      </div>
    </>
  )
}

export default SignupLayout
