import { ComponentPropsWithoutRef } from 'react'
import { Transition } from '@headlessui/react'
import { cn } from 'ui/lib'

export type MenuItemTransitionProps = ComponentPropsWithoutRef<typeof Transition>

export default function MenuItemTransition({ className, ...props }: MenuItemTransitionProps) {
  return (
    <Transition
      enter="transition duration-200 ease-in-out"
      enterFrom="transform opacity-0 -translate-x-2"
      enterTo="transform opacity-100 translate-x-0"
      leave="transition duration-100 ease-in-out"
      leaveFrom="transform opacity-100 translate-x-0"
      leaveTo="transform opacity-0 -translate-x-2"
      className={cn('whitespace-nowrap', className)}
      {...props}
    />
  )
}
