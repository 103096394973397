import { forwardRef, InputHTMLAttributes } from 'react'
import { Control, Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { format, isValid, parse } from 'date-fns'

const PATTERN = 'm{/}`d{/}`Y'

const formatDate = (date: Date) => {
  if (!date) return
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const prependZero = (value: number): string => (value < 10 ? `0${value}` : `${value}`)

  return [prependZero(month), prependZero(day), year].join('/')
}

const parseToDate = (str?: string) => {
  if (!str) return
  const [month, day, year] = str.split('/').map((value) => Number.parseInt(value))
  return new Date(year, month - 1, day)
}

const formatForAccept = (value?: string) => {
  if (!value) {
    return value
  } else {
    const [month, day, year] = value.split('/')
    return [year, month, day].join('-')
  }
}

export interface DateInput extends InputHTMLAttributes<HTMLInputElement> {
  readonly control?: Control<any>
}

// eslint-disable-next-line react/display-name
export const DateInput = forwardRef<HTMLInputElement, DateInput>(
  ({ name, control, ...props }, ref) => {
    return (
      <Controller
        name={name!}
        control={control}
        render={({ field }) => {
          let reformatedValue = field.value
          if (field.value) {
            const parsedValue = parse(field.value, 'yyyy-MM-dd', new Date())
            if (isValid(parsedValue)) {
              reformatedValue = format(parsedValue, 'MM/dd/yyyy')
            }
          }
          return (
            <IMaskInput
              ref={ref}
              type="text"
              lazy={true}
              pattern={PATTERN}
              format={formatDate}
              parse={parseToDate}
              mask={Date}
              name={name}
              value={reformatedValue}
              onBlur={field.onBlur}
              onAccept={(value?: string) => field.onChange(formatForAccept(value))}
              {...props}
            />
          )
        }}
      />
    )
  },
)

export default DateInput
