import { ComponentProps, FC, forwardRef, useContext } from 'react'
import { PlatformComponentsContext } from './PlatformComponentsContext'

export interface PlatformProseProps extends ComponentProps<'div'> {
  readonly contentString: string
}

// eslint-disable-next-line react/display-name
export const PlatformProse: FC<PlatformProseProps> = forwardRef((props, ref) => {
  const { prose: Component } = useContext(PlatformComponentsContext)
  return <Component ref={ref} {...props} />
})
