import { axios } from 'api/lib'
import { AxiosRequestConfig } from 'axios'
import { AmityUser } from './types'

const BASE_URL = process.env.NEXT_PUBLIC_AMITY_API_URL

export type UpdateUserRequest = {
  userId: string
  displayName: string
  avatarFileId?: string
  metadata?: {
    city?: string | null
    headline?: string | null
  }
}

export type UpdateUserResponse = {
  users: AmityUser
}

export async function updateUser(
  user: UpdateUserRequest,
  axiosConfig: AxiosRequestConfig = {},
): Promise<UpdateUserResponse> {
  const { data } = await axios.put(`/v3/users`, user, { ...axiosConfig, baseURL: BASE_URL })
  return data
}

export async function addUsers(
  userIds: string[],
  communityId: string,
  axiosConfig: AxiosRequestConfig = {},
) {
  const { data } = await axios.post<void>(
    `/v3/communities/${communityId}/users`,
    { userIds },
    { baseURL: BASE_URL, ...axiosConfig },
  )
  return data
}

export async function removeUsers(
  userIds: string[],
  communityId: string,
  axiosConfig: AxiosRequestConfig = {},
): Promise<void> {
  const { data } = await axios.delete<void>(`/v3/communities/${communityId}/users`, {
    data: { userIds },
    baseURL: BASE_URL,
    ...axiosConfig,
  })
  return data
}
