import { ComponentProps, FC, ReactNode, useRef, useState } from 'react'
import {
  UseFloatingOptions,
  useFloating,
  offset,
  shift,
  flip,
  FloatingArrow,
  arrow,
} from '@floating-ui/react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Button } from 'ui/src/components/forms'

// Hint arrow settings
// The default arrow size is 7px (from @floating-ui/react)
const ARROW_HEIGHT = 7
// gap between the arrow and the element which the arrow points to
const GAP = 2

interface HintProps extends ComponentProps<'span'> {
  label?: ReactNode
  labelClassName?: string
  hintContainerClassName?: string
  hintClassName?: string
}

export const Hint: FC<HintProps> = ({
  className,
  children,
  label,
  labelClassName,
  hintClassName,
  hintContainerClassName,
  ...props
}) => {
  const arrowRef = useRef<SVGSVGElement>(null)

  const options: UseFloatingOptions = {
    placement: 'right',
    strategy: 'fixed',
    middleware: [
      shift(),
      offset(ARROW_HEIGHT + GAP),
      flip({
        fallbackPlacements: ['top', 'bottom'],
      }),
      arrow({ element: arrowRef }),
    ],
  }

  const { refs, floatingStyles, context } = useFloating(options)

  const [isVisible, setIsVisible] = useState<boolean>(false)

  return (
    <>
      <span
        data-testid="hint"
        className={clsx('group relative', className)}
        onMouseOver={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onClick={(event) => {
          setIsVisible(true)
          event.stopPropagation()
          event.preventDefault()
        }}
        ref={refs.setReference}
        {...props}
      >
        {label ? (
          label
        ) : (
          <>
            <InformationCircleIcon
              className={clsx(
                'text-deep-teal-300 group-hover:text-deep-teal-500 h-5 w-5 cursor-pointer',
                isVisible && 'text-deep-teal-500',
                labelClassName,
              )}
            />
          </>
        )}
      </span>

      {isVisible ? (
        <div
          className={clsx('z-50 w-full px-6 sm:w-72 sm:px-0 lg:w-[396px]', hintContainerClassName)}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          <div
            className={clsx(
              'text-taupe-500 bg-deep-gray-800 rounded text-xs font-normal leading-4 shadow-lg',
              'items-left flex flex-col',
              'mx-auto w-full max-w-[432px] p-2',
              hintClassName,
            )}
          >
            {children}
            <Button
              className="mt-2 block self-center sm:hidden"
              size="sm"
              onClick={() => setIsVisible(false)}
            >
              Got it
            </Button>
          </div>
          <FloatingArrow ref={arrowRef} context={context} className="fill-deep-gray-800" />
        </div>
      ) : null}
    </>
  )
}
export default Hint
