import { useEffect, useState } from 'react'
import { first, last } from 'lodash'
import { H } from 'mdast-util-to-hast'
import remark from 'remark'
import html from 'remark-html'
import { Node, Parent, Literal } from 'unist'

export interface MarkdownNavItem {
  id: string
  name: string
}

interface MarkdownHookResult {
  htmlString: string
  nav: Array<MarkdownNavItem>
}

interface CustomHastOptions {
  pref?: string
  classes?: CustomHastClassesOptions
  sanitize?: boolean
}

interface CustomHastClassesOptions {
  [key: string]: string
}

export const useMarkdownWithNav = (
  markdown: string,
  options?: Partial<CustomHastOptions>,
): MarkdownHookResult => {
  const [content, setContent] = useState<MarkdownHookResult>({ htmlString: '', nav: [] })

  options = {
    pref: 'nav',
    classes: {},
    sanitize: true,
    ...(options || {}),
  }

  useEffect(() => {
    let isSubscribed = true
    markdownWithNavToHtml(markdown, options!).then(
      (content) => isSubscribed && setContent(content!),
    )
    return () => {
      isSubscribed = false
    }
  }, [markdown])

  return content
}

const remarkSplitByGroupUsingHeadings =
  ({ pref, navOut }: { pref: string; navOut: MarkdownNavItem[] }) =>
  (root: Parent): Parent => {
    const groupedNodes = reduceNodesToGroupsByHeading(root.children)
    root.children = []
    groupedNodes
      .map((nodes, nodesIndex) => mapNodeGroupToGroupNodeAndNavItem(pref, nodes, nodesIndex))
      .forEach(([groupNode, nav]) => {
        navOut.push(nav)
        root.children.push(groupNode)
      })
    return root
  }

const reduceNodesToGroupsByHeading = (nodes: Node[]): Node[][] => {
  const noHeaderAtBeginning = first(nodes)?.type !== 'heading'
  const groupedNodesAcc: Node[][] = noHeaderAtBeginning ? [[]] : []
  const groupedNodes = nodes.reduce((groupedNodes, node) => {
    if (node.type === 'heading') {
      groupedNodes.push([node]) // create new group
    } else {
      last(groupedNodes)?.push(node) // push node to the last node
    }
    return groupedNodes
  }, groupedNodesAcc)
  return groupedNodes
}

const getValueFromHeadingNode = (node: Parent): string => {
  if (node.type !== 'heading') {
    return ''
  }
  const firstChildrenTextNode = first(node.children) as Literal
  return (firstChildrenTextNode?.value as string) || ''
}

const mapNodeGroupToGroupNodeAndNavItem = (
  pref: string,
  nodes: Node[],
  nodesIndex: number,
): [Node, MarkdownNavItem] => {
  const firstNodeInGroup = first(nodes) as Parent
  const nav = {
    id: `${pref}-${nodesIndex + 1}`,
    name:
      nodesIndex === 0
        ? getValueFromHeadingNode(firstNodeInGroup) || 'Introduction'
        : getValueFromHeadingNode(firstNodeInGroup),
  }
  const groupNode = {
    type: 'group',
    children: nodes,
    position: {
      start: firstNodeInGroup.position?.start!,
      end: last(nodes)?.position?.end!,
    },
    ...nav,
  }
  return [groupNode, nav]
}

// It is happened because by default mdast-util-to-hast do not expect mdast child nodes
// there is 'passThrough'
// It's configurable on mdast-util-to-hast level but it's not configurable on remark-html leven
const processChildNodes = (h: H, node: Parent): Node[] => {
  return node.children?.map((child) => h.handlers[child.type](h, child)) || []
}

const customMdNodesHandlersFactory = (classes: CustomHastClassesOptions) => ({
  group(h: H, node: Parent) {
    return h(
      node,
      'div',
      {
        class: classes[node.type] || '',
        id: node.id,
      },
      processChildNodes(h, node),
    )
  },
  paragraph(h: H, node: Parent) {
    return h(node, 'p', { class: classes[node.type] || '' }, processChildNodes(h, node))
  },
  heading(h: H, node: Parent) {
    return h(
      node,
      'h' + node.depth,
      { class: classes[node.type] || '' },
      processChildNodes(h, node),
    )
  },
})

async function markdownWithNavToHtml(
  markdown: string,
  options: CustomHastOptions,
): Promise<MarkdownHookResult> {
  const navOut: MarkdownNavItem[] = []
  const result = await remark()
    .use(remarkSplitByGroupUsingHeadings as any, {
      pref: options.pref,
      navOut,
    })
    .use(html as any, {
      handlers: customMdNodesHandlersFactory(options.classes!),
      sanitize: options.sanitize,
    })
    .process(markdown)
  return {
    htmlString: result.toString(),
    nav: navOut,
  }
}
