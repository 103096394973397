import { FC, forwardRef } from 'react'
import clsx from 'clsx'
import { PlatformProseProps } from 'ui/components/platform'

// eslint-disable-next-line react/display-name
export const Prose: FC<PlatformProseProps> = forwardRef(
  ({ className, contentString, ...props }, ref) => {
    return (
      <div
        className={clsx('prose prose-md', className)}
        role="region"
        ref={ref}
        dangerouslySetInnerHTML={{ __html: contentString }}
        {...props}
      />
    )
  },
)
