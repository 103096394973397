import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'
import { Card } from '../../containers/Card'

export interface SignupCardProps extends HTMLAttributes<HTMLDivElement> {
  readonly title: string
  readonly subtitle?: string
}

const SignupCard: FC<SignupCardProps> = ({ title, subtitle, children, className, ...props }) => {
  return (
    <Card
      className={clsx('sm:px-16 sm:py-16 mx-auto max-w-md sm:min-w-[472px]', className)}
      {...props}
    >
      <div>
        <img className="h-6 mx-auto" src="/images/meetperry-deep-teal.svg" />
      </div>

      <h1 className="mt-8 px-4 sm:px-0 text-3xl text-deep-teal-600 leading-9 font-bold">{title}</h1>

      {subtitle && <p className="mt-2 px-4 sm:px-0 text-deep-teal-800">{subtitle}</p>}

      <div className="px-4 sm:px-0">{children}</div>
    </Card>
  )
}

export default SignupCard
