import { FC, ReactNode } from 'react'

import { PlatformDialogContext } from 'ui/components/containers'
import { Dialog } from 'ui/components/containers/Dialog2'

export const PlatformDialogProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <PlatformDialogContext.Provider value={{ component: Dialog }}>
      {children}
    </PlatformDialogContext.Provider>
  )
}
