import { FC } from 'react'
import { isNumber } from 'lodash'

export const ResultsCount: FC<{ count?: number }> = ({ count }) => (
  <>
    {isNumber(count) && (
      <span className="text-deep-teal-400 text-xs font-semibold">
        {`${count} result${count > 1 || count === 0 ? 's' : ''} found`}
      </span>
    )}
  </>
)
