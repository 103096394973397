import { FC } from 'react'
import { FieldError, Control, Controller } from 'react-hook-form'
import clsx from 'clsx'
import { AutocompleteInputProps, AutocompleteInput } from './AutocompleteInput'
import { Label, Error } from '../Field'

export interface AutocompleteFieldProps
  extends Omit<AutocompleteInputProps, 'onSelectedValueChange' | 'selectedValue' | 'error'> {
  readonly className?: string
  readonly name: string
  readonly label: string | JSX.Element
  readonly error?: FieldError
  readonly hideError?: boolean
  readonly control: Control<any>
}

const AutocompleteField: FC<AutocompleteFieldProps> = ({
  className,
  disabled,
  name,
  label,
  error,
  hideError,
  control,
  ...props
}) => {
  return (
    <div className={clsx({ 'opacity-25': disabled }, className)}>
      {label && <Label name={name}>{label}</Label>}

      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, name } }) => {
          return (
            <AutocompleteInput
              name={name}
              selectedValue={value}
              onSelectedValueChange={onChange}
              hasError={!!error}
              {...props}
            />
          )
        }}
      />

      {!hideError && (
        <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
          {error?.message}
        </Error>
      )}
    </div>
  )
}

export default AutocompleteField
