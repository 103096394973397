import { createContext } from 'react'
import mitt, { Emitter, EventType } from 'mitt'
import { LinkComponent } from '../../components/navigation/Link'

// Routing Context Provider must be implemented on application level.
// It depends on the platform specific SDKs.

export interface RouterUrlObject {
  query: any
  pathname?: string
}

export type RouterUrl = string | RouterUrlObject

export type RoutingContextValue<LinkElement extends HTMLElement = HTMLElement> = {
  readonly pathname: string
  readonly query: Record<string, string | string[]>
  readonly push: (url: RouterUrl, options?: { scroll?: boolean }) => void
  readonly replace: (url: RouterUrl, options?: { scroll?: boolean }) => void
  readonly LinkComponent: LinkComponent<LinkElement>
  readonly events: Emitter<RouterEvents>
  readonly baseUrl: string
}

type RouterEventsContext = { pathname: string } & Record<any, unknown>

type RouterEventsBase = {
  type: EventType
  context: RouterEventsContext
}

export type RouterEvents = RouterEventsBase & Record<any, unknown>

export const RoutingContext = createContext<RoutingContextValue>({
  pathname: '',
  query: {},
  baseUrl: '',

  LinkComponent: () => {
    throw new Error('RoutingContext.LinkComponent not implemented')
  },

  push: () => {
    throw new Error('RoutingContext.push() not implemented')
  },

  replace: () => {
    throw new Error('RoutingContext.replace() not implemented')
  },

  events: mitt<RouterEvents>(),
})
