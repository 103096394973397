import { Role } from 'api/dto'
import { useSession } from 'auth'
import { LDFlag, useFlag } from 'launchdarkly'

export function useIsSmsReceivingConsentModalAllowed() {
  const { user, agreements, socialProfileNotActivated } = useSession()

  // feature flag
  const isFlagEnabled = useFlag(LDFlag.CollectPhoneNumber)
  // do not show for admins who are possible can get same app layout as far we do not move admin pages from the app completely
  const isInvestor = user?.roles?.includes(Role.INVESTOR)
  // check if all agreements are signed
  const isAllAgreementsSigned = !!agreements?.every((a) => !!a.signatureDate)

  return isFlagEnabled && isInvestor && isAllAgreementsSigned && !socialProfileNotActivated
}
