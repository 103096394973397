import { MutableRefObject, useEffect, useState } from 'react'

interface ScrollToBottomElement extends HTMLElement {
  scrollToBottom: (duration?: number) => Promise<void>
}

export const useScrollToBottom = <T extends ScrollToBottomElement | HTMLElement | Window>(
  scrollContainerRef: MutableRefObject<T>,
): {
  setScrollToBottom: (scroll: boolean) => void
} => {
  const [scrollToBottom, setScrollToBottom] = useState(false)

  useEffect(() => {
    if (scrollToBottom) {
      const current = scrollContainerRef.current

      if (current && 'scrollToBottom' in current) {
        const scrollToBottomElement = current as ScrollToBottomElement
        // Note: For unknown reason scroll to bottom doesn't
        // work and has some distance from the bottom left.
        // Likely because of it's async nature.
        // scrollToBottomElement.scrollToBottom()
        // Note 2: Duration chosen to be 500ms experimentally
        // to be close to the 'smooth' scroll behavior visually.
        // Note 3: Arbitrary delay is required, otherwise
        // the behavior is not stable.
        setTimeout(() => {
          scrollToBottomElement.scrollToBottom(500).finally(() => {
            setScrollToBottom(false)
          })
        }, 100)
      } else if (current instanceof HTMLElement) {
        current.scrollTo({
          top: current.scrollHeight,
          behavior: 'smooth',
        })
        setScrollToBottom(false)
      } else if (current instanceof Window) {
        current.scrollTo({
          top: current.innerHeight,
          behavior: 'smooth',
        })
        setScrollToBottom(false)
      }
    }
  }, [scrollToBottom, scrollContainerRef])

  return {
    setScrollToBottom,
  }
}
