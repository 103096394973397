import { ComponentProps, FC } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Button } from 'ui/src/components/forms'

interface LeaveConfirmationOverlayProps extends ComponentProps<'div'> {
  onProceed: () => void
  onSaveAndProceed: () => void
}

export const LeaveConfirmationOverlay: FC<LeaveConfirmationOverlayProps> = ({
  onSaveAndProceed,
  onProceed,
}) => (
  <>
    <div className="bg-rangitoto-100 mx-auto h-24 w-24 rounded-full p-6 md:h-12 md:w-12 md:p-2">
      <ExclamationTriangleIcon className="color-rangitoto-600 h-12 w-12 md:h-8 md:w-8" />
    </div>
    <h1 className="color-rangitoto-900 mt-6 text-center text-xl font-medium leading-7 md:mt-5 md:text-lg md:leading-6">
      You have unsaved changes
    </h1>
    <p className="color-rangitoto-500 mt-2 text-center text-base font-normal leading-6 md:text-sm md:leading-5">
      The latest changes to your profile have not be saved, do you want to?
    </p>
    <div className="mt-6 space-y-4 md:flex md:flex-row-reverse md:space-y-0 ">
      <Button className="w-full md:ml-4" onClick={() => onSaveAndProceed()}>
        Save and proceed
      </Button>
      <Button uiType="regular" className="w-full" onClick={() => onProceed()}>
        Proceed without saving
      </Button>
    </div>
  </>
)
