import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

const Card: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => {
  return (
    <div
      className={clsx('bg-white overflow-hidden shadow rounded-lg px-4 py-5 sm:p-8', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default Card
