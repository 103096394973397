import { FC } from 'react'
import { Event } from 'api/cms'
import { eventDateFormatter } from '../eventDateFormatter'

export const EventCardDate: FC<{ event: Event }> = ({ event }) => {
  const { time, timezone, shortDay } = eventDateFormatter(event)

  return (
    <div className="border-deep-teal-100 divide-deep-teal-100 divide-y divide-dashed rounded-b-lg">
      {event.eventDateTbd ? (
        <p className="text-deep-teal-400 p-1 text-center text-sm leading-5">
          {event.eventDateTbdText || 'TBD'}
        </p>
      ) : (
        <>
          <div className="text-deep-teal-300 py-1 text-center text-sm font-bold leading-5">
            {shortDay}
          </div>
          <div className="text-deep-teal-300 py-1 text-center text-xs leading-4">
            <div>{time}</div>
            <div>{timezone}</div>
          </div>
        </>
      )}
    </div>
  )
}
