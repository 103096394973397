import { useCallback, useEffect, useState } from 'react'
import { TopAnnouncementBanner } from 'api/cms'
import { useAxios } from 'api/lib'
import { differenceInHours } from 'date-fns'
import { pick } from 'lodash'
import { sha256 } from 'ui/lib'

const TOP_ANNOUNCEMENT_BANNER_KEY = 'top-announcement-banner'
const TOP_ANNOUNCEMENT_BANNER_HASH_KEY = 'top-announcement-banner-hash'
const FORCE_SHOW_TIMEOUT = 24 // hours
const REVALIDATE_TIMEOUT = 60000 // one minute in milliseconds

export const useTopAnnouncementBanner = () => {
  const { data: banner, mutate } = useAxios<TopAnnouncementBanner>('/api/top-announcement-banner')

  const [shown, setShown] = useState(false)

  // Force revalidate every minute
  // Will enforce to show banner if it's changed and user not refreshes page or mobile app
  useEffect(() => {
    const timeoutId = window.setInterval(() => mutate(), REVALIDATE_TIMEOUT)
    return () => window.clearInterval(timeoutId)
  }, [mutate, banner])

  useEffect(() => {
    if (!banner) {
      return setShown(false)
    }

    const lastDismissedBannerDateString = window.localStorage.getItem(TOP_ANNOUNCEMENT_BANNER_KEY)
    const lastDismissedBannerHash = window.localStorage.getItem(TOP_ANNOUNCEMENT_BANNER_HASH_KEY)

    getBannerHash(banner).then((currentBannerHash) => {
      const isItNewBanner = currentBannerHash !== lastDismissedBannerHash

      if (!lastDismissedBannerDateString || isItNewBanner) {
        return setShown(true)
      }

      const lastDismissedBannerDate = new Date(lastDismissedBannerDateString)
      const currentDate = new Date()

      const timeoutExceeded =
        differenceInHours(currentDate, lastDismissedBannerDate) >= FORCE_SHOW_TIMEOUT

      setShown(timeoutExceeded)
    })
  }, [banner])

  const dismiss = useCallback(async () => {
    if (!banner) {
      return
    }

    setShown(false)

    window.localStorage.setItem(TOP_ANNOUNCEMENT_BANNER_KEY, new Date().toISOString())
    window.localStorage.setItem(TOP_ANNOUNCEMENT_BANNER_HASH_KEY, await getBannerHash(banner))
  }, [banner])

  return {
    shown,
    banner,
    dismiss,
  }
}

const getBannerHash = async (banner: TopAnnouncementBanner) => {
  const observableObject = pick(banner, ['content', 'mobileContent', 'url'])
  const observableFieldsString = Object.values(observableObject).join(', ')
  return sha256(observableFieldsString)
}
