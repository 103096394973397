import { FieldError, Control, Controller } from 'react-hook-form'
import clsx from 'clsx'
import { PhoneInput, PhoneInputProps } from './PhoneInput'
import { Label, Error } from '../Field'

interface PhoneFieldProps extends Omit<PhoneInputProps, 'value' | 'onChange'> {
  readonly label?: string
  readonly error?: FieldError
  readonly control: Control<any>
}

export function PhoneField({
  className,
  control,
  disabled,
  name,
  label,
  error,
  ...props
}: PhoneFieldProps) {
  return (
    <div className={clsx({ 'opacity-25': disabled }, className)}>
      {label && (
        <Label name={name} htmlFor="phone-number">
          {label}
        </Label>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, name } }) => {
          return (
            <PhoneInput
              id="phone-number"
              name={name}
              value={value}
              onChange={onChange}
              error={!!error}
              disabled={disabled}
              {...props}
            />
          )
        }}
      />

      <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
        {error?.message}
      </Error>
    </div>
  )
}
