import { FC } from 'react'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

export const LoadMoreButton: FC<{
  isLoading?: boolean
  pageSize?: number
  onLoadMore: () => void
}> = ({ isLoading = false, pageSize = 10, onLoadMore }) => {
  return (
    <div className="mt-4 flex justify-center sm:mt-6">
      <button
        className={clsx(
          'flex items-center text-orange-600',
          'disabled:pointer-events-none disabled:text-orange-300',
          'hover:text-orange-800',
        )}
        onClick={onLoadMore}
      >
        <ArrowPathIcon className={clsx('mr-2 h-8 w-8', isLoading && 'animate-spin')} />
        <span className="text-lg font-semibold leading-6">Load {pageSize} more...</span>
      </button>
    </div>
  )
}
