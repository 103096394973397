import { ComponentProps, FC } from 'react'
import clsx from 'clsx'

export const GroupCardLoading: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  return (
    <div className={clsx('flex animate-pulse gap-4', className)} {...props}>
      <div className="h-9 w-9 shrink-0 self-start rounded-full bg-slate-200" />

      <div className="flex flex-col gap-2 w-full">
        <div className="h-4 w-full bg-slate-200" />
        <div className="h-4 w-full bg-slate-200" />
      </div>
    </div>
  )
}
