import { forwardRef, InputHTMLAttributes } from 'react'
import { Control, Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { AnyMaskedOptions } from 'imask'

export interface MaskedInput extends InputHTMLAttributes<HTMLInputElement> {
  readonly mask: AnyMaskedOptions
  readonly unmask?: boolean
  readonly control: Control<any>
}

// eslint-disable-next-line react/display-name
export const MaskedInput = forwardRef<HTMLInputElement, MaskedInput>(
  ({ name, control, mask, unmask, ...props }, ref) => {
    return (
      <Controller
        name={name!}
        control={control}
        render={({ field }) => (
          <IMaskInput
            ref={ref}
            name={name}
            unmask={unmask}
            onBlur={field.onBlur}
            value={field.value}
            onAccept={(value: unknown) => field.onChange(value)}
            {...mask}
            {...props}
          />
        )}
      />
    )
  },
)

export default MaskedInput
