import { FC, ReactNode, useRef } from 'react'
import { axios } from 'api/lib'
import { InternalAxiosRequestConfig } from 'axios'
import NProgress from 'nprogress'

const RequestCounter = {
  counter: 0,
  increment: function () {
    this.counter++
    if (!NProgress.isStarted() && this.counter == 1) {
      NProgress.start()
    } else {
      NProgress.inc()
    }
  },
  decrement: function () {
    this.counter--
    if (this.counter <= 0) {
      NProgress.done()
    }
  },
}

export const AxiosProgressInterceptorInitializer: FC<{ children: ReactNode }> = ({ children }) => {
  const instance = useRef(axios)
  const api = instance.current

  api.interceptors.request.use(async (request): Promise<InternalAxiosRequestConfig> => {
    RequestCounter.increment()
    return request
  })

  api.interceptors.response.use(
    async (response) => {
      RequestCounter.decrement()
      return response
    },
    (error) => {
      RequestCounter.decrement()
      throw error
    },
  )

  return <>{children}</>
}
