import { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { Button } from 'ui/src/components/forms'
import { AlternativeInvestmentsChartMobile } from './AlternativeInvestmentsChartMobile'

export function AlternativeInvestmentsCardMobile({
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={clsx('relative space-y-4 rounded-lg bg-[#f0f0f0] p-4 shadow', className)}
      {...props}
    >
      <h3 className="font-brand text-deep-teal-500 text-lg font-medium leading-tight">
        {`Introducing meetperry's Guide to Alternative Investments`}
      </h3>
      <Button
        href="https://meetperry.com/mp-guide-to-alternative-investments"
        uiType="secondary"
        target="_blank"
        data-trackclick={JSON.stringify({
          action: 'Taxonomy Viewed',
        })}
      >
        Read Now
      </Button>

      <div className="absolute bottom-0 right-4 h-[61px] w-[127px]">
        <AlternativeInvestmentsChartMobile />
      </div>
    </div>
  )
}
