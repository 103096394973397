import { FC, ReactNode } from 'react'
import clsx from 'clsx'

interface ExpandableContainerProps {
  readonly collapsedHeight: number
  readonly expanded?: boolean
  readonly className?: string
  readonly children: ReactNode
}

export const ExpandableContainer: FC<ExpandableContainerProps> = ({
  collapsedHeight,
  children,
  className,
  expanded = false,
}) => {
  return (
    <div
      className={clsx('overflow-hidden', className)}
      style={{ height: expanded ? 'auto' : collapsedHeight }}
    >
      {children}
    </div>
  )
}
