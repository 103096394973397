import analytics from './../analytics'

interface EventConfig {
  dataAttr?: string
}

interface Config {
  [key: string]: EventConfig
}

const eventHandler = (event: Event, dataName: string, dataAttrSelector: string) => {
  const targetElement = event.target as HTMLElement

  const closestElementWithDataAttr: HTMLElement = targetElement?.matches(dataAttrSelector)
    ? targetElement!
    : targetElement.closest(dataAttrSelector)!

  if (closestElementWithDataAttr) {
    const payload = JSON.parse(closestElementWithDataAttr.dataset[dataName]!)
    analytics?.track(payload.action || event.type, payload)
  }
}

const jsEventsTrack = (
  config: Config = {
    click: {},
    submit: {},
  },
): any => ({
  name: 'js-events-plugin',
  ready() {
    Object.entries(config).forEach(([eventName, eventConfig]) => {
      const dataName = `track${eventConfig.dataAttr || eventName}`
      const dataAttrSelector = `[data-${dataName}]`
      document.addEventListener(eventName, (event) =>
        eventHandler(event, dataName, dataAttrSelector),
      )
    })
  },
})

export default jsEventsTrack
