import { AnchorHTMLAttributes, FC } from 'react'
import { useRoutingContext } from '../../lib/navigation/useRoutingContext'

type CommonLinkElementProps<T extends HTMLElement> = AnchorHTMLAttributes<T>

export interface LinkProps<T extends HTMLElement = HTMLElement> extends CommonLinkElementProps<T> {
  routerDirection?: 'forward' | 'back' | 'root' | 'none'
}

export type LinkComponent<T extends HTMLElement> = FC<LinkProps<T>>

export function Link<T extends HTMLElement = HTMLElement>(props: LinkProps<T>) {
  const { LinkComponent } = useRoutingContext<T>()
  return <LinkComponent {...props} />
}
