import { Dialog } from 'ui/components/containers/Dialog2'
import {
  SmsReceivingConsentDialog,
  useSmsReceivingConsentDialogShowing,
} from 'ui/views/sms-receiving-consent'

export function SmsReceivingConsentModal() {
  const { isShown, close } = useSmsReceivingConsentDialogShowing()

  return (
    <Dialog open={isShown} onClose={close}>
      <SmsReceivingConsentDialog onFinish={close} />
    </Dialog>
  )
}
