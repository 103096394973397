import { ComponentProps, FC, Fragment, PropsWithChildren } from 'react'
import { Event } from 'api/cms'
import clsx from 'clsx'
import { EventCardLoading, CarouselEventCard } from 'ui/components/content'
import { EventsEmptyState } from './EventsEmptyState'

type EventsProps = Pick<ComponentProps<'div'>, 'className'> & {
  events: Event[]
  isLoading: boolean
}

const EventCardDivider: FC = () => <div className="bg-deep-teal-100 h-px last-of-type:hidden" />

const EventCardDividerLoading: FC = () => <div className="h-px animate-pulse bg-slate-200" />

const EventsContainer: FC<PropsWithChildren<Pick<ComponentProps<'div'>, 'className'>>> = ({
  className,
  children,
}) => <div className={clsx('flex w-full flex-col gap-4 py-2', className)}>{children}</div>

export const Events: FC<EventsProps> = ({ events, isLoading, className }) => {
  if (isLoading) {
    return (
      <EventsContainer className={className}>
        <EventCardLoading />
        <EventCardDividerLoading />
        <EventCardLoading />
        <EventCardDividerLoading />
        <EventCardLoading />
      </EventsContainer>
    )
  }

  if (!events.length) {
    return (
      <EventsContainer className={className}>
        <EventsEmptyState />
      </EventsContainer>
    )
  }

  return (
    <EventsContainer className={className}>
      {events.map((event) => (
        <Fragment key={event.id}>
          <CarouselEventCard event={event} />
          <EventCardDivider />
        </Fragment>
      ))}
    </EventsContainer>
  )
}
