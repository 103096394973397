import { FC, Fragment } from 'react'
import { Transition, Combobox } from '@headlessui/react'
import clsx from 'clsx'

interface AutocompleteOptions {
  inputValue: string
  open: boolean
  options: string[]
}

export const AutocompleteOptions: FC<AutocompleteOptions> = ({ inputValue, open, options }) => {
  return (
    <Transition
      as={Fragment}
      show={open}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Combobox.Options
        className={clsx(
          'absolute z-10 mt-1 max-h-60 w-full overflow-auto', // box model
          'rounded-md shadow-lg ring-1 ring-black ring-opacity-5', // border
          'ring-deep-teal-900 bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm',
        )}
        data-testid={`combobox-options`}
      >
        {inputValue.length > 0 && options.indexOf(inputValue) === -1 && (
          <Combobox.Option
            className={({ active }) =>
              clsx(
                'relative cursor-pointer select-none px-4 py-2',
                active ? 'bg-orange-100 text-orange-800' : '',
              )
            }
            value={inputValue}
          >
            <span className={`block truncate`}>{inputValue}</span>
          </Combobox.Option>
        )}
        {options.map((value) => (
          <Combobox.Option
            key={value}
            className={({ active }) =>
              clsx(
                'relative cursor-pointer select-none px-4 py-2',
                active ? 'bg-orange-100 text-orange-800' : '',
              )
            }
            value={value}
          >
            <span className={`block truncate`}>{value}</span>
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Transition>
  )
}
