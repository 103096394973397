import { axios } from 'api/lib'
import { AxiosRequestConfig } from 'axios'
import qs from 'qs'
import {
  AmityCommunity,
  AmityMember,
  AmityUser,
  GetGroupsResult,
  GetGroupMembersResult,
  QueryGroupMembersParams,
  QueryGroupsParams,
} from './types'

const BASE_URL = process.env.NEXT_PUBLIC_AMITY_API_URL

const AMITY_MAX_PAGE_SIZE = 100

export async function getGroup(
  groupId: string,
  axiosConfig: AxiosRequestConfig = {},
): Promise<GetGroupsResult> {
  const { data } = await axios.get<GetGroupsResult>(`/v3/communities/${groupId}`, {
    baseURL: BASE_URL,
    ...axiosConfig,
  })

  return data
}

export async function getGroups(
  params: QueryGroupsParams = {},
  axiosConfig: AxiosRequestConfig = {},
): Promise<GetGroupsResult> {
  const encodedParams = params ? `?${qs.stringify(params)}` : ''
  const { data } = await axios.get<GetGroupsResult>('/v3/communities' + encodedParams, {
    baseURL: BASE_URL,
    ...axiosConfig,
  })

  return data
}

export async function getAllGroups(
  params: QueryGroupsParams = {},
  axiosConfig: AxiosRequestConfig = {},
): Promise<AmityCommunity[]> {
  const allGroups = [] as AmityCommunity[]
  let hasNextPage = false
  let optionToken = undefined

  do {
    const options = { limit: AMITY_MAX_PAGE_SIZE, token: optionToken }
    const groups = await getGroups({ ...params, options }, axiosConfig)
    allGroups.push(...groups.communities)

    optionToken = groups.paging?.next
    hasNextPage = Boolean(optionToken)
  } while (hasNextPage)

  return allGroups
}

export async function getGroupMembers(
  { groupId, ...params }: QueryGroupMembersParams,
  axiosConfig: AxiosRequestConfig = {},
): Promise<GetGroupMembersResult> {
  const encodedParams = params ? `?${qs.stringify(params)}` : ''
  const { data } = await axios.get<GetGroupMembersResult>(
    `/v3/communities/${groupId}/users` + encodedParams,
    {
      baseURL: BASE_URL,
      ...axiosConfig,
    },
  )

  return data
}

export async function getAllGroupMembers(
  { groupId, ...params }: QueryGroupMembersParams,
  axiosConfig: AxiosRequestConfig = {},
): Promise<GetGroupMembersResult> {
  const allUsers = [] as AmityUser[]
  const allMembers = [] as AmityMember[]
  let hasNextPage = false
  let optionToken = undefined

  do {
    const options = { limit: AMITY_MAX_PAGE_SIZE, token: optionToken }
    const members = await getGroupMembers({ groupId, ...params, options }, axiosConfig)
    allUsers.push(...members.users)
    allMembers.push(...members.communityUsers)

    optionToken = members.paging?.next
    hasNextPage = Boolean(optionToken)
  } while (hasNextPage)

  return {
    users: allUsers,
    communityUsers: allMembers,
  }
}

export function joinGroup(groupId: string) {
  return axios.post(`/v3/communities/${groupId}/join`, {}, { baseURL: BASE_URL })
}

export function leaveGroup(groupId: string) {
  return axios.delete(`/v3/communities/${groupId}/leave`, { baseURL: BASE_URL })
}
