import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { UserPhoneNumber, UserPhoneNumberConsent } from 'api/dto'
import { object, SchemaOf, string, mixed } from 'yup'

export const userPhoneNumberSchema: SchemaOf<Pick<UserPhoneNumber, 'phoneNumber' | 'consent'>> =
  object().shape({
    phoneNumber: string()
      .ensure()
      .when('consent', {
        is: UserPhoneNumberConsent.CONSENTED,
        then: (schema) =>
          schema
            .test({
              name: 'isValidPhoneNumber',
              message: 'Phone number is invalid',
              test: (value: string) => isPossiblePhoneNumber(value),
            })
            .required('Phone number is required'),
      }),
    consent: mixed<UserPhoneNumberConsent>()
      .required()
      .oneOf([UserPhoneNumberConsent.CONSENTED, UserPhoneNumberConsent.DECLINED]),
  })
