import { ComponentProps, FC } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

interface TagProps extends ComponentProps<'span'> {
  tag: string
  onRemove: (tag: string) => void
}

export const Tag: FC<TagProps> = ({ tag, onRemove }) => (
  <span
    className={clsx(
      'flex w-fit shrink-0 grow-0 items-center', // box model
      'bg-deep-teal-50 rounded-lg', // background
    )}
  >
    <span className="text-deep-teal-500 break-all pl-2 text-xs font-semibold leading-5">{tag}</span>
    <XMarkIcon
      data-testid="tag-remove-button"
      className="text-deep-teal-500 ml-0.5 mr-1 h-3 w-3 cursor-pointer shrink-0"
      onClick={() => onRemove(tag)}
    />
  </span>
)
