import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { Link } from 'ui/components/navigation'
import { Card } from '../Card'

export interface CardSectionProps extends ComponentProps<typeof Card> {
  readonly title: string
  readonly href?: string
  readonly linkLabel?: string
  readonly isLoading?: boolean
}

export const CardSection: FC<CardSectionProps> = ({
  className,
  children,
  title,
  href,
  linkLabel = 'View all',
  isLoading,
  ...props
}) => {
  return (
    <Card className={clsx('flex flex-col gap-4', className)} {...props}>
      <div className="flex items-center justify-between">
        <div className="text-deep-teal-900 flex-1 text-base font-bold leading-5">
          {isLoading ? (
            <div className="h-4 w-1/2 shrink-0 animate-pulse self-start bg-slate-200" />
          ) : (
            <>{title}</>
          )}
        </div>
        <div className="text-sm font-semibold">
          {!isLoading && href && (
            <Link href={href} className="text-orange-600 hover:text-orange-800">
              {linkLabel}
            </Link>
          )}
        </div>
      </div>
      <div className="flex-1">{children}</div>
    </Card>
  )
}
