import { ComponentProps, FC } from 'react'
import { AmityCommunity } from 'amity/src/api/types'
import { Carousel, GroupCard, GroupCardLoading } from 'ui/components/content'
import GroupsEmptyState from './GroupsEmptyState'

type GroupsCarouselProps = Pick<ComponentProps<'div'>, 'className'> & {
  groups: AmityCommunity[]
  isLoading: boolean
  slidesToShow?: number
}

export const GroupsCarousel: FC<GroupsCarouselProps> = ({
  groups,
  isLoading,
  slidesToShow,
  className,
}) => {
  if (isLoading) {
    return <GroupCardLoading className={className} />
  }

  if (!groups?.length) {
    return <GroupsEmptyState className={className} />
  }

  return (
    <Carousel className={className} slidesToShow={slidesToShow}>
      {groups.map((group) => (
        <GroupCard group={group} key={group.communityId} />
      ))}
    </Carousel>
  )
}
