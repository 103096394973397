import { useEffect, useState } from 'react'
import remark from 'remark'
import html from 'remark-html'

export const useMarkdown = (
  markdown: string,
  { sanitize }: { sanitize?: boolean } = { sanitize: true },
): string => {
  const [content, setContent] = useState('')

  useEffect(() => {
    let isSubscribed = true

    const markdownToHtml = async (markdown: string) => {
      const result = await remark()
        .use(html as any, {
          sanitize,
        })
        .process(markdown)
      if (isSubscribed) {
        setContent(result.toString())
      }
    }

    markdownToHtml(markdown).catch(console.error)

    return () => {
      isSubscribed = false
    }
  }, [markdown])

  return content
}
