import { FC } from 'react'
import clsx from 'clsx'

export interface TagProps {
  readonly label: string
  readonly disabled?: boolean
  readonly onRemove?: () => void
}

const Tag: FC<TagProps> = ({ label, disabled, onRemove }) => {
  return (
    <span
      className={clsx(
        'bg-deep-teal-50 text-deep-teal-500 inline-flex items-center rounded-full py-0.5 pl-2 text-sm font-medium',
        disabled ? 'pr-2' : 'pr-0.5',
      )}
    >
      {label}

      {onRemove && !disabled && (
        <button
          type="button"
          className="text-deep-teal-500 hover:bg-deep-teal-100 ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:outline-none"
          onClick={() => onRemove()}
        >
          <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      )}
    </span>
  )
}

export default Tag
