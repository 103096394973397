import { ComponentPropsWithoutRef, FC } from 'react'
import SidebarLayout from './SidebarLayout'

export type DefaultLayoutProps = ComponentPropsWithoutRef<'div'> & {
  title?: string
  hideToolbar?: boolean
  Toolbar?: FC
  banner?: JSX.Element
  hideAnnouncementBanner?: boolean
  hideMobileSearchBar?: boolean
  bgColorClass?: string
}

export function DefaultLayout(props: DefaultLayoutProps) {
  const hideNavInSidebar = !!props?.Toolbar || props?.hideToolbar
  const { hideMobileSearchBar, ...rest } = props

  return (
    <SidebarLayout
      hideMobileSearchBar={hideMobileSearchBar}
      hideNavigation={hideNavInSidebar}
      {...rest}
    />
  )
}
