import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  useContext,
} from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link, useRouter } from 'ui/components/navigation'
import { cn } from 'ui/lib'
import { MenuItemType } from './data'
import MenuItemTransition from './MenuItemTransition'
import { SidebarContext } from './SidebarContext'

export interface MenuItemProps extends MenuItemType, ComponentPropsWithoutRef<'li'> {}

export default forwardRef(function MenuItem(
  { name, href, icon, items, className, ...props }: MenuItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) {
  const router = useRouter()
  const sidebar = useContext(SidebarContext)

  // In the closed state even top-level links should be open sidebar rather than navigating to the page.
  const Component = sidebar?.isClosed ? 'span' : href ? Link : 'span'

  const hasActiveChild = items?.some((item) => router.pathname === item?.href)
  const hasNoChildren = !items || items.length === 0
  const groupIsActive = router.pathname === href

  return (
    <Disclosure ref={ref} as="li" className={cn('flex flex-col gap-2', className)} {...props}>
      {({ open: groupIsOpen }) => {
        const highlightGroup =
          // "Home" is an example of such group
          (groupIsActive && hasNoChildren) ||
          // Highlight groups when either they are closed or
          // when the sidebar is closed (group may remain opened).
          (hasActiveChild && (!groupIsOpen || sidebar.isClosed))

        const handleOnDisclosureButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
          if (sidebar.isClosed) {
            sidebar.openSidebar()
          }

          // If sidebar is closed, do not change the state of the group menu items (open/close)
          if (sidebar.isClosed) {
            e.preventDefault()
          }

          // Prevent closing sidebar when clicking on the group menu item.
          e.stopPropagation()
        }

        return (
          <>
            <Disclosure.Button onClick={handleOnDisclosureButtonClick}>
              <Component
                className={cn(
                  'flex items-center gap-4 rounded py-0.5 pr-3 transition-colors duration-100',
                  highlightGroup
                    ? 'bg-navbar-active ring-navbar-stroke text-white ring-1'
                    : 'hover:bg-navbar-hover',
                )}
                href={href}
              >
                <MenuItemIcon icon={icon} title={name} />
                <MenuItemLabel
                  show={sidebar.isOpen}
                  className="flex flex-1 items-center justify-between text-white"
                >
                  <span>{name}</span>

                  {items?.length > 0 && (
                    <ChevronDownIcon
                      className={cn('size-4 text-white/50', groupIsOpen && 'rotate-180 transform')}
                    />
                  )}
                </MenuItemLabel>
              </Component>
            </Disclosure.Button>

            {items?.length > 0 && sidebar.isOpen && (
              <Disclosure.Panel as="ul" className="space-y-2">
                {items.map((item) => {
                  const isActive = router.pathname === item?.href
                  return (
                    <li
                      key={item?.name}
                      className={cn(
                        'whitespace-nowrap rounded transition-colors',
                        isActive
                          ? 'bg-navbar-active ring-navbar-stroke text-white ring-1'
                          : 'hover:bg-navbar-hover text-white/70',
                      )}
                    >
                      <Disclosure.Button as="div">
                        <Link href={item?.href}>
                          <MenuItemLabel show={sidebar.isOpen} className="block py-2 pl-16">
                            {item?.name}
                          </MenuItemLabel>
                        </Link>
                      </Disclosure.Button>
                    </li>
                  )
                })}
              </Disclosure.Panel>
            )}
          </>
        )
      }}
    </Disclosure>
  )
})

export type MenuItemIconProps = ComponentPropsWithoutRef<'span'> & {
  icon: MenuItemType['icon']
  active?: boolean
}

function MenuItemIcon({ active, icon: Icon, ...props }: MenuItemIconProps) {
  return (
    <span
      className={cn([
        'rounded p-1.5 transition-colors duration-150',
        active ? 'bg-deep-teal-300/40 ring-navbar-stroke text-white ring-1' : 'text-deep-teal-100',
      ])}
      {...props}
    >
      <Icon className="size-5" />
    </span>
  )
}

export type MenuItemLabelProps = ComponentPropsWithoutRef<'span'> & {
  show?: boolean
}

function MenuItemLabel({ className, ...props }: MenuItemLabelProps) {
  return (
    <MenuItemTransition className={cn('text-sm/5 font-medium', className)} as="span" {...props} />
  )
}
