import { FC } from 'react'
import clsx from 'clsx'

export interface EmptyStateCardComponentProps {
  readonly className?: string
  readonly title: string
  readonly content: string
  readonly icon?: FC<any>
}

export const EmptyStateCardComponent: FC<EmptyStateCardComponentProps> = ({
  className,
  title,
  content,
  icon: Icon,
}) => (
  <div className={clsx('flex rounded-lg bg-white px-4 py-12 shadow sm:p-6', className)}>
    <div className="ml-auto mr-auto flex flex-col items-center sm:flex-row">
      {Icon ? (
        <Icon className="text-deep-teal-100 mr-4 mt-6 mb-6 h-12 w-12 sm:mt-0 sm:mb-0 sm:h-9 sm:w-9" />
      ) : (
        <Logo className="mr-4 mt-6 mb-6 self-center sm:mt-0 sm:mb-0" />
      )}
      <div className="flex-col text-center sm:text-left">
        <h3 className="mb-2 text-base text-xl font-medium leading-7 text-orange-600">{title}</h3>
        <p className="text-deep-teal-900 max-w-2xl text-base font-normal leading-5">{content}</p>
      </div>
    </div>
  </div>
)

const Logo: FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="29"
    viewBox="0 0 48 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0323 1.71464C20.9347 1.71464 22.4173 2.29942 23.4799 3.46896C24.0213 4.09329 24.4411 4.82774 24.7149 5.62914C24.9886 6.43055 25.1107 7.28274 25.0739 8.13562V21.7315H22.5959V8.87973C22.5959 5.5945 21.2569 3.95189 18.5789 3.95189C17.9553 3.92728 17.3344 4.05098 16.7604 4.31419C16.1864 4.57739 15.6734 4.97361 15.2581 5.47459C14.3954 6.55566 13.9468 7.94899 14.003 9.37251V21.7315H11.525V8.87973C11.525 5.5945 10.1876 3.95189 7.51261 3.95189C6.94119 3.92825 6.37112 4.02719 5.83559 4.24293C5.30007 4.45866 4.8098 4.78689 4.39335 5.20849C3.55482 6.09232 3.05055 7.27918 2.978 8.53971V21.7315H0.5V2.24192H2.82685V4.62207C3.95057 2.6805 5.70182 1.71136 8.08058 1.71464C10.6273 1.71464 12.3755 2.80534 13.3251 4.98673C14.6016 2.80534 16.5039 1.71464 19.0323 1.71464Z"
      fill="#CBCECA"
    />
    <path
      d="M38.6502 0.502283C39.8123 0.472829 40.9653 0.728676 42.0216 1.25039C43.078 1.77211 44.01 2.54597 44.7467 3.5132C46.3316 5.5139 47.1255 8.14208 47.1286 11.3977C47.1316 14.6534 46.33 17.2816 44.7239 19.2823C43.9832 20.2481 43.0484 21.0204 41.99 21.5411C40.9316 22.0618 39.7771 22.3173 38.6136 22.2883C36.0852 22.2883 34.1721 21.3865 32.8743 19.5829V29H27.9825V1.12812H32.5216V3.83351C33.9049 1.61269 35.9478 0.502283 38.6502 0.502283ZM34.0149 16.0201C34.43 16.5761 34.9589 17.0215 35.5604 17.3215C36.1618 17.6215 36.8195 17.7681 37.4822 17.7497C38.1376 17.7739 38.7889 17.6306 39.3829 17.3317C39.9768 17.0328 40.4965 16.5866 40.8992 16.0299C41.7542 14.8768 42.1817 13.3344 42.1817 11.4027C42.1817 9.47096 41.7603 7.92361 40.9175 6.76064C40.5234 6.19889 40.009 5.74783 39.4181 5.44583C38.8272 5.14384 38.1772 4.99982 37.5234 5.02604C36.855 5.00679 36.1916 5.1553 35.5858 5.4598C34.9799 5.76431 34.4484 6.21644 34.0332 6.78036C33.0862 8.09924 32.6071 9.73805 32.682 11.4027C32.6109 13.0621 33.0825 14.6959 34.0149 16.0201Z"
      fill="#CBCECA"
    />
  </svg>
)
