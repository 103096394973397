import { ComponentProps, FC } from 'react'
import { cn } from 'ui/lib'

export const Card: FC<ComponentProps<'div'>> = ({ className, children, ...props }) => {
  return (
    <div className={cn('rounded-lg bg-white px-4 py-6 shadow', className)} {...props}>
      {children}
    </div>
  )
}
