import { FC, Fragment, HTMLAttributes, ReactNode, useRef } from 'react'
import { useFloating, offset, UseFloatingOptions } from '@floating-ui/react'
import { Menu as HeadlessMenu, Transition } from '@headlessui/react'
import clsx from 'clsx'

export interface MenuProps extends HTMLAttributes<HTMLButtonElement> {
  readonly button: ReactNode
  readonly items: MenuItem[]
  readonly floatingOptions?: Partial<UseFloatingOptions>
  readonly onOpened?: () => void
}

export interface MenuItem {
  readonly action: () => void
  readonly label: string
  readonly disabled?: boolean
}

const DEFAULT_OPTIONS: Partial<UseFloatingOptions> = {
  placement: 'bottom-start',
  middleware: [
    offset({
      mainAxis: 20,
      crossAxis: 0,
    }),
  ],
}

const Menu: FC<MenuProps> = ({ onOpened, button, items, floatingOptions, ...props }) => {
  const openedRef = useRef(false)

  const { refs } = useFloating({ ...DEFAULT_OPTIONS, ...floatingOptions })

  return (
    <HeadlessMenu as="div">
      {({ open }) => {
        if (open && !openedRef.current) {
          onOpened?.()
          openedRef.current = true
        } else if (!open && openedRef.current) {
          openedRef.current = false
        }

        return (
          <>
            <div>
              <HeadlessMenu.Button ref={refs.setReference} {...props}>
                {button}
              </HeadlessMenu.Button>
            </div>

            <div ref={refs.setFloating} className="relative">
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <HeadlessMenu.Items
                  className="absolute right-0 z-10 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  static
                >
                  <div className="flex flex-col py-1">
                    {items.map((item) => (
                      <HeadlessMenu.Item key={item.label}>
                        {({ active }) => (
                          <button
                            onClick={item.action}
                            disabled={item.disabled}
                            className={clsx(
                              { 'bg-gray-100': active && !item.disabled },
                              { 'cursor-not-allowed opacity-50': item.disabled },
                              'block px-4 py-2 text-left text-sm text-gray-700',
                            )}
                          >
                            {item.label}
                          </button>
                        )}
                      </HeadlessMenu.Item>
                    ))}
                  </div>
                </HeadlessMenu.Items>
              </Transition>
            </div>
          </>
        )
      }}
    </HeadlessMenu>
  )
}

export default Menu
