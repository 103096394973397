import { ComponentProps, FC } from 'react'
import { MinusCircleIcon } from '@heroicons/react/24/outline'
import { FireIcon } from '@heroicons/react/24/solid'
import { InvestmentOpportunity } from 'api/cms'
import clsx from 'clsx'

interface StateProps extends ComponentProps<'div'> {
  state: InvestmentOpportunity['state']
}
export const InvestmentOpportunityState: FC<StateProps> = ({ state, className }) => {
  const color = state === 'new' ? 'text-navy-400' : state === 'closed' ? 'text-taupe-700' : null
  const icon =
    state === 'new' ? (
      <FireIcon className={clsx(color, 'h-3 w-3')} />
    ) : state === 'closed' ? (
      <MinusCircleIcon className={clsx(color, 'h-3 w-3')} />
    ) : null

  const stateLabel = state !== 'open' ? state : ''

  return (
    <div className={clsx(color, 'flex items-center space-x-1', className)}>
      {icon}
      <span className="text-xs font-semibold uppercase">{stateLabel}</span>
    </div>
  )
}
