import { FC, Fragment } from 'react'
import { range } from 'lodash'

export const NominatorsListLoading: FC = () => {
  return (
    <div className="flex animate-pulse flex-col">
      {range(0, 5).map((id) => (
        <Fragment key={id}>
          <NominatorElementLoading />
          <div className="bg-deep-teal-100 my-2 h-px last-of-type:hidden" />
        </Fragment>
      ))}
    </div>
  )
}

const NominatorElementLoading: FC = () => {
  return (
    <div className="flex gap-x-4 py-2">
      <div className="h-11 w-11 shrink-0 self-start rounded-full bg-slate-200" />
      <div className="flex flex-col gap-y-1">
        <div className="h-6 w-32 bg-slate-200"></div>
        <div className="h-5 w-24 bg-slate-200"></div>
      </div>
    </div>
  )
}
