import { ComponentProps, FC } from 'react'
import clsx from 'clsx'

export const ArticleCardLoading: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  return (
    <div className={clsx('space-y-1', className)} {...props}>
      <div className="h-5 w-full bg-slate-200" />
      <div className="h-4 w-full bg-slate-200" />
      <div className="h-5 w-1/2 bg-slate-200" />
    </div>
  )
}
