import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { cn } from 'ui/lib'

export enum FoundingMemberLabelTheme {
  light = 'light',
  dark = 'dark',
}

interface FoundingMemberLabelProps extends ComponentProps<'div'> {
  theme: FoundingMemberLabelTheme
}

const bgStylesMap = {
  [FoundingMemberLabelTheme.light]: 'bg-taupe-200',
  [FoundingMemberLabelTheme.dark]: 'bg-deep-teal-500',
}

const textStylesMap = {
  [FoundingMemberLabelTheme.light]: 'text-deep-teal-500',
  [FoundingMemberLabelTheme.dark]: 'text-taupe-200',
}

export const FoundingMemberLabel: FC<FoundingMemberLabelProps> = ({
  className,
  theme,
  ...props
}) => (
  <div
    className={cn(
      'flex w-fit shrink-0 items-center space-x-1 rounded-full px-2 py-1',
      bgStylesMap[theme],
      className,
    )}
    {...props}
  >
    <FoundingMemberLabelIcon />
    <span className={clsx('text-nowrap text-sm font-medium', textStylesMap[theme])}>
      Founding Member
    </span>
  </div>
)

const FoundingMemberLabelIcon = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.179 12.3473C13.179 12.9702 13.0563 13.587 12.818 14.1625C12.5796 14.7381 12.2302 15.261 11.7897 15.7015C11.3492 16.142 10.8263 16.4914 10.2508 16.7298C9.67524 16.9681 9.0584 17.0908 8.43547 17.0908C7.81253 17.0908 7.1957 16.9681 6.62018 16.7298C6.04466 16.4914 5.52174 16.142 5.08126 15.7015C4.64077 15.261 4.29136 14.7381 4.05298 14.1625C3.81459 13.587 3.69189 12.9702 3.69189 12.3473L8.43547 12.3473H13.179Z"
      fill="#CC5E1A"
    />
    <path
      d="M13.179 12.3473C13.179 12.9702 13.0563 13.587 12.818 14.1625C12.5796 14.7381 12.2302 15.261 11.7897 15.7015C11.3492 16.142 10.8263 16.4914 10.2508 16.7298C9.67524 16.9681 9.0584 17.0908 8.43547 17.0908C7.81253 17.0908 7.1957 16.9681 6.62018 16.7298C6.04466 16.4914 5.52174 16.142 5.08126 15.7015C4.64077 15.261 4.29136 14.7381 4.05298 14.1625C3.81459 13.587 3.69189 12.9702 3.69189 12.3473"
      stroke="#0A373C"
      strokeWidth="0.803347"
    />
    <path
      d="M16 0.909149C10.958 0.909149 8.91333 6.17554 8.52122 8.80873C6.14943 2.83336 1.85216 3.30963 0 4.29468C0.795696 7.7682 5.70632 9.30604 8.06216 9.64077L7.72744 13.0646H9.06635L8.73162 9.64077H9.06635C14.3149 9.31178 15.8757 3.68261 16 0.909149Z"
      fill="#CC5E1A"
    />
    <path d="M12.002 5.72922L8.44434 9.07297V9.70353L12.002 5.72922Z" fill="white" />
    <path d="M3.87334 6.52305L8.44438 9.71014L8.44436 9.07301L3.87334 6.52305Z" fill="white" />
  </svg>
)
