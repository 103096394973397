import { FC } from 'react'
import clsx from 'clsx'
import { Card } from '../../Card'

export const GroupCardLoadingState = () => {
  return (
    <Card className="flex flex-col">
      <div className="flex w-full">
        {/* Avatar */}
        <div className="mr-4 h-11 w-11 shrink-0 rounded-full bg-slate-200" />

        <div className="flex w-full justify-between">
          {/* Group Name & Type */}
          <div className="flex flex-col items-start gap-1">
            <div className="h-5 w-36 bg-slate-200" />
            <div className="h-4 w-24 bg-slate-200" />
          </div>

          {/* Desktop Metadata */}
          <GroupMetadataLoading className="hidden sm:flex" />
        </div>
      </div>

      <div className="mt-3 h-10 bg-slate-200 sm:ml-[60px] sm:mt-2"></div>
      {/* Mobile Metadata */}
      <GroupMetadataLoading className="mt-1 flex sm:hidden" />
    </Card>
  )
}

const GroupMetadataLoading: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={clsx('align-center flex justify-between gap-4', className)}>
      <div className="h-5 w-[90px] bg-slate-200"></div>
      <div className="h-5 w-[128px] bg-slate-200"></div>
    </div>
  )
}
