import { FC, Fragment } from 'react'
import { Transition, Combobox } from '@headlessui/react'
import clsx from 'clsx'

interface TagAutocompleteOptions {
  open: boolean
  options: string[]
}

export const TagAutocompleteOptions: FC<TagAutocompleteOptions> = ({ open, options }) => {
  return (
    <Transition
      as={Fragment}
      show={open && options.length !== 0}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Combobox.Options
        className={clsx(
          'absolute z-10 mt-1 max-h-60 w-full overflow-auto', // box model
          'ring-1 ring-black ring-opacity-5 shadow-lg rounded-md', // border
          'bg-white py-1 text-base shadow-lg ring-1 ring-deep-teal-900 ring-opacity-5 focus:outline-none sm:text-sm',
        )}
      >
        {options.map((tag) => (
          <Combobox.Option
            key={tag}
            className={({ active }) =>
              clsx(
                'relative cursor-pointer select-none py-2 px-4',
                active ? 'bg-orange-100 text-orange-800' : '',
              )
            }
            value={tag}
          >
            <span className={`block truncate`}>{tag}</span>
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Transition>
  )
}
