import { ComponentProps, FC } from 'react'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import clsx from 'clsx'

// https://github.com/AdeleD/react-paginate#readme

type PaginationProps = Pick<ComponentProps<'div'>, 'className'> &
  Pick<
    ReactPaginateProps,
    'pageCount' | 'forcePage' | 'onPageChange' | 'nextLabel' | 'previousLabel'
  >

const pageClassName =
  'flex align-center w-9 h-9 border -ml-px first-of-type:ml-0 border-deep-teal-100 overflow-hidden'
const pageLinkClassName =
  'w-full h-full flex justify-center items-center bg-white hover:bg-deep-teal-50'
const activeClassName = 'border-orange-600 z-10'
const activeLinkClassName = '!text-orange-600 !bg-orange-100 hover:!bg-orange-200'

export const Pagination: FC<PaginationProps> = ({
  pageCount,
  onPageChange,
  forcePage = 0,
  previousLabel = '<',
  nextLabel = '>',
  className,
}) => {
  return (
    <div className={clsx('flex', className)}>
      <ReactPaginate
        className="flex select-none list-none rounded-md shadow"
        pageCount={pageCount}
        forcePage={forcePage}
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        pageClassName={pageClassName}
        pageLinkClassName={pageLinkClassName}
        breakClassName={pageClassName}
        breakLinkClassName={pageLinkClassName}
        previousClassName={clsx(pageClassName, '!rounded-tl-md !rounded-bl-md')}
        previousLinkClassName={pageLinkClassName}
        nextClassName={clsx(pageClassName, 'rounded-tr-md rounded-br-md')}
        nextLinkClassName={pageLinkClassName}
        activeClassName={activeClassName}
        activeLinkClassName={activeLinkClassName}
        onPageChange={onPageChange}
      />
    </div>
  )
}
