import { FC, forwardRef, SelectHTMLAttributes } from 'react'
import clsx from 'clsx'

// eslint-disable-next-line react/display-name
export const Select = forwardRef<any, SelectHTMLAttributes<HTMLSelectElement>>(
  ({ className, defaultValue, children, onChange, disabled, ...props }, ref) => {
    return (
      <select
        ref={ref}
        role="combobox"
        className={clsx(!disabled ? 'cursor-pointer' : 'cursor-not-allowed', className)}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        {...props}
      >
        {children}
      </select>
    )
  },
)

export const SelectItem: FC<{
  disabled?: boolean
  value: any
  name: string
}> = ({ value, name, disabled = false }) => {
  return (
    <option
      role="option"
      className={clsx(disabled ? 'text-deep-teal-300' : 'text-deep-teal-600')}
      disabled={disabled}
      value={value}
    >
      {name}
    </option>
  )
}
