import { FC, HTMLAttributes } from 'react'
import { TrashIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { Avatar } from 'ui/components/content'

export interface RemovableAvatarProps extends HTMLAttributes<HTMLDivElement> {
  src?: string
  onRemove: () => void
}

export const RemovableAvatar: FC<RemovableAvatarProps> = ({ src, onRemove }) => {
  return (
    <div className="relative mr-5">
      <Avatar className="h-12 w-12" src={src} />
      {src && (
        <div
          className={clsx(
            'absolute -bottom-1 -right-1 h-6 w-6',
            'text-deep-teal-800 cursor-pointer rounded-full',
            'border-taupe-600 border bg-white shadow-sm',
            'hover:bg-deep-teal-50 hover:border-deep-teal-200 focus:bg-deep-teal-100 focus:border-deep-teal-300',
          )}
          data-testid="remove-button"
          onClick={(event) => {
            onRemove()
            event.stopPropagation()
            event.preventDefault()
          }}
        >
          <TrashIcon className="text-deep-teal-800 m-[3px] h-4 w-4" />
        </div>
      )}
    </div>
  )
}
