import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

// There are a lot of other features which can be smoothly incorporated:
// https://tailwindui.com/components/application-ui/layout/dividers

export const Divider: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  return (
    <div className={clsx('relative', className)} {...props}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
    </div>
  )
}
