import { ComponentProps, FC, PropsWithChildren, useContext } from 'react'
import { PlatformComponentsContext } from './PlatformComponentsContext'

export type PlatformTooltipProps = PropsWithChildren<{
  icon?: FC<ComponentProps<'svg'>>
  title?: string
}>

export const PlatformTooltip: FC<PlatformTooltipProps> = (props) => {
  const { tooltip: Component } = useContext(PlatformComponentsContext)
  return <Component {...props} />
}
