import { ComponentProps, FC, Fragment } from 'react'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { TrophyIcon } from '@heroicons/react/24/outline'
import { UserSocialProfile, UserSocialProfilePreview } from 'api/src/dto'
import clsx from 'clsx'
import { LDFlag, useFlag } from 'launchdarkly'
import { take } from 'lodash'
import {
  AmityAvatar,
  AvatarGroup,
  FoundingMemberLabel,
  FoundingMemberLabelTheme,
} from 'ui/components/content'
import { Link } from 'ui/components/navigation'
import { useRoutingContext } from 'ui/lib/navigation'

interface MemberSummaryProps extends ComponentProps<'div'> {
  profile: UserSocialProfile
}

export const MemberSummary: FC<MemberSummaryProps> = ({ profile }) => {
  const { baseUrl } = useRoutingContext()
  const displayNominatorsLeaderboard = useFlag<boolean>(LDFlag.DisplayNominatorsLeaderboard)

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex gap-3">
        <Link href={'/profile'}>
          <AmityAvatar className="h-12 w-12 shrink-0 grow-0" fileId={profile.avatarFileId!} />
        </Link>
        <div className="flex flex-col justify-center space-y-1">
          <Link href={'/profile'} className="flex flex-col justify-center space-y-1">
            {profile.isFoundingMember && (
              <FoundingMemberLabel theme={FoundingMemberLabelTheme.dark} />
            )}
            <div className="font-brand text-taupe-500 text-2xl font-semibold leading-7">{`${profile.firstName} ${profile.lastName}`}</div>
          </Link>
          <NominatedBy members={profile.nominatedBy} />
        </div>
      </div>

      <Nominates members={profile.hasNominated} />
      {displayNominatorsLeaderboard && (
        <div className="flex justify-center">
          <Link
            className="font-bold text-orange-400 hover:text-orange-200"
            href={`${baseUrl}/nominators`}
          >
            <div className="flex items-center gap-2">
              <span className="text-sm leading-5">Nominations Leaderboard</span>
              <TrophyIcon className="h-4 w-4" />
            </div>
          </Link>
        </div>
      )}
    </div>
  )
}

interface NominatedByProps extends ComponentProps<'div'> {
  members: UserSocialProfilePreview[]
}

const NominatedBy: FC<NominatedByProps> = ({ members, className, ...props }) => {
  const { baseUrl } = useRoutingContext()

  return members.length ? (
    <div className={clsx('flex gap-2', className)} {...props}>
      <span className="text-deep-teal-200 whitespace-nowrap text-sm leading-5">Nominated by</span>
      <p className="whitespace-break-spaces text-sm font-bold leading-5">
        {members.map((member, index, { length }) => (
          <Fragment key={member.id}>
            <Link
              href={`${baseUrl}/members/${member.userId}`}
              className="inline text-orange-400 hover:text-orange-200"
            >
              {`${member.firstName} ${member.lastName}`}
            </Link>
            {index < length - 1 ? <span className="text-orange-400">, </span> : null}
          </Fragment>
        ))}
      </p>
    </div>
  ) : null
}

interface NominatesProps extends ComponentProps<'div'> {
  members: UserSocialProfilePreview[]
}

const Nominates: FC<NominatesProps> = ({ members }) => {
  const { baseUrl } = useRoutingContext()
  const remainingNominationsCount = members.length - 3

  return (
    <div className="border-deep-teal-900 bg-deep-teal-500 flex gap-3 rounded-lg border px-4 py-3">
      <span className="text-deep-teal-200 w-full whitespace-nowrap text-sm leading-6">
        {members.length ? 'Members Nominated' : 'Hit the button to nominate a member'}
      </span>
      <Link href={`${baseUrl}/nominated-members`} className="flex">
        <div className="flex gap-2 ">
          <AvatarGroup className="flex-none">
            {take(members, 3).map((nomination) => (
              <AmityAvatar
                className="flex h-6 w-6 shrink-0 rounded-full ring-2 ring-white"
                fileId={nomination.avatarFileId!}
                key={nomination.id}
              />
            ))}
          </AvatarGroup>
          {remainingNominationsCount > 0 ? (
            <span className="text-deep-teal-200 flex-none whitespace-nowrap text-sm leading-6">{`+${remainingNominationsCount}`}</span>
          ) : null}
        </div>
      </Link>
      {!!members.length && <div className="bg-deep-teal-400 h-6 w-px flex-none" />}
      <Link
        href={`${baseUrl}/nominations`}
        className="group"
        data-testid="nominations-link"
        data-trackclick={JSON.stringify({ action: 'Clicked Nominate Member - Homepage' })}
      >
        <PlusCircleIcon className="h-6 w-6 flex-none text-orange-400 group-hover:text-orange-200" />
      </Link>
    </div>
  )
}
