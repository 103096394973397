import { ComponentProps, FC } from 'react'
import { QueryGroupsParams } from 'amity/api'
import { useGroups } from 'amity/api/browser'
import clsx from 'clsx'
import { LoadMoreButton } from 'ui/components/navigation'
import { GroupCard } from './GroupCard'
import { GroupCardLoadingState } from './GroupCardLoadingState'

export interface GroupListProps extends ComponentProps<'div'> {
  readonly params: QueryGroupsParams
  readonly showGroupAccessBadge?: boolean
  readonly emptyStateText: string
}

const GroupList: FC<GroupListProps> = ({
  params,
  emptyStateText,
  showGroupAccessBadge,
  ...props
}) => {
  const {
    flatData: groups,
    isLoading,
    hasNext,
    loadNext,
  } = useGroups(params, {
    revalidateFirstPage: false,
    revalidateOnFocus: false,
  })

  return (
    <div {...props}>
      {isLoading ? (
        <ul
          role="list"
          data-testid="groups-loading-state"
          className={clsx('animate-pulse space-y-6')}
        >
          <GroupCardLoadingState />
          <hr className="mt-6 h-[1px] bg-slate-200 sm:ml-20" />
          <GroupCardLoadingState />
        </ul>
      ) : (
        <>
          <ul role="list" className="space-y-4">
            {groups.length > 0 ? (
              <>
                {groups.map((group) => (
                  <li key={group.communityId}>
                    <GroupCard group={group} showGroupAccessBadge={showGroupAccessBadge} />
                  </li>
                ))}
              </>
            ) : (
              <div className="text-deep-teal-300 flex items-center justify-center rounded-lg bg-white px-7 py-10 text-center shadow">
                {emptyStateText}
              </div>
            )}
          </ul>
          {hasNext && <LoadMoreButton onLoadMore={loadNext} isLoading={isLoading} />}
        </>
      )}
    </div>
  )
}

export default GroupList
