import { createContext } from 'react'

export type SidebarContextValue = {
  isOpen: boolean
  isClosed: boolean
  openSidebar: () => void
  closeSidebar: () => void
}

export const SidebarContext = createContext<SidebarContextValue>({
  isOpen: false,

  get isClosed() {
    return !this.isOpen
  },

  openSidebar: () => {
    throw new Error('SidebarContext.openSidebar() not implemented')
  },

  closeSidebar: () => {
    throw new Error('SidebarContext.closeSidebar() not implemented')
  },
})
