import { Context, useContext } from 'react'
import { RoutingContext, RoutingContextValue } from './RoutingContext'

export const useRoutingContext = <T extends HTMLElement = HTMLElement>() => {
  const context = useContext<RoutingContextValue<T>>(
    RoutingContext as unknown as Context<RoutingContextValue<T>>,
  )
  if (!context) {
    throw new Error('useRoutingContext must be used under RoutingContextProvider')
  }
  return context
}
