import { ComponentProps, FC } from 'react'
import { Announcement } from 'api/cms'
import { Card, Carousel } from 'ui/components/content'
import { AnnouncementCard } from './AnnouncementCard'

interface AnnouncementsCarouselProps extends ComponentProps<'div'> {
  readonly announcements: Announcement[]
}

export const AnnouncementsCarousel: FC<AnnouncementsCarouselProps> = ({
  className,
  announcements,
}) => {
  return (
    <>
      {announcements.length > 0 && (
        <Card className={className}>
          {announcements.length > 1 ? (
            <Carousel data-testid="carousel">
              {announcements.map((announcement) => (
                <AnnouncementCard key={announcement.id} announcement={announcement} />
              ))}
            </Carousel>
          ) : (
            <AnnouncementCard announcement={announcements[0]} />
          )}
        </Card>
      )}
    </>
  )
}
