import { FC } from 'react'
import { FieldError, Control, Controller } from 'react-hook-form'
import clsx from 'clsx'
import { ListAutocomplete, ListAutocompleteProps } from './ListAutocomplete'
import { Label, Error } from '../Field'

export interface ListAutocompleteFieldProps
  extends Omit<ListAutocompleteProps, 'list' | 'onChange' | 'hasError'> {
  readonly className?: string
  readonly disabled?: boolean
  readonly name: string
  readonly label: string | JSX.Element
  readonly error?: FieldError
  readonly control: Control<any>
}

export const ListAutocompleteField: FC<ListAutocompleteFieldProps> = ({
  className,
  disabled,
  name,
  label,
  error,
  control,
  ...props
}) => {
  return (
    <div className={clsx({ 'opacity-25': disabled }, className)} data-testid={`combobox-${name}`}>
      {label && <Label name={name}>{label}</Label>}

      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <ListAutocomplete
              list={value || []}
              onChange={onChange}
              isOptionDisabled={(item) => [...(value ?? [])].some(({ id }) => id === item.id)}
              hasError={!!error}
              {...props}
            />
          )
        }}
      />

      {error?.message && (
        <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
          {error?.message}
        </Error>
      )}
    </div>
  )
}
