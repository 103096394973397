import { FC, Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

export interface Item {
  id: number
}

export interface ListAutocompleteProps {
  readonly list: Item[]
  readonly autocomplete: Item[]
  readonly onQueryChange: (query: string) => void
  readonly onChange: (list: Item[]) => void
  readonly isOptionDisabled?: (item: Item) => boolean
  readonly display: (item: Item) => string
  readonly hasError?: boolean
}

export const ListAutocomplete: FC<ListAutocompleteProps> = ({
  list,
  autocomplete,
  onQueryChange,
  onChange,
  display,
  isOptionDisabled,
  hasError,
}) => {
  const [selected, setSelected] = useState<Item | null>(null)
  const [query, setQuery] = useState('')

  return (
    <div className="w-full">
      <Combobox
        value={selected}
        onChange={(item: Item) => {
          setSelected(item)
          setQuery('')
          onChange([...list, item])
        }}
      >
        <div className="relative mt-1">
          <div
            className={clsx(
              'relative flex w-full flex-wrap', // box model
              'border-taupe-600 rounded-md border shadow-sm', // border and shadow
              'focus-within:!border-navy-500', // focus // TODO does not work
              'hover:border-taupe-700', // hover
              'cursor-default overflow-hidden',
              hasError && '!border-pure-red !hover:border-pure-red !focus-within:border-pure-red',
            )}
          >
            <Combobox.Input
              className="text-deep-teal-800 block w-full border-none text-sm leading-5 focus:ring-0"
              displayValue={() => query}
              onChange={(event) => {
                setQuery(event.target.value)
                onQueryChange(event.target.value)
              }}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="text-deep-teal-300 h-5 w-5" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
            show={query !== '' && query.length >= 3}
          >
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {autocomplete.length === 0 ? (
                <div className="text-taupe-800 relative cursor-default select-none px-4 py-2">
                  Nothing found.
                </div>
              ) : (
                autocomplete.map((item) => (
                  <Combobox.Option
                    key={item.id}
                    className={({ active }) =>
                      clsx(
                        'relative select-none px-4 py-2',
                        isOptionDisabled?.(item)
                          ? 'text-gray-300'
                          : active
                          ? 'bg-deep-teal-500 cursor-pointer text-white'
                          : 'text-taupe-800 cursor-default',
                      )
                    }
                    value={item}
                    disabled={isOptionDisabled?.(item)}
                  >
                    {display(item)}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>

      <div className="my-2 flex flex-wrap">
        {list.map((item) => (
          <div
            className={clsx(
              'my-1 mr-1 flex w-fit shrink-0 grow-0 items-center', // box model
              'bg-deep-teal-50 rounded-full', // background
            )}
            key={item.id}
          >
            <span className="text-deep-teal-500 pl-2 text-xs font-semibold leading-5">
              {display(item)}
            </span>
            <XMarkIcon
              data-testid="item-remove-button"
              className="text-deep-teal-500 ml-0.5 mr-1 h-3 w-3 cursor-pointer"
              onClick={() => onChange(list.filter((filteredItem) => filteredItem.id !== item.id))}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
