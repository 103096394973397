const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
})

export function formatNumber(value: number): string {
  return numberFormatter.format(value)
}

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  signDisplay: 'never',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export function formatPercent(value: number): string {
  return value < 0 ? `(${percentFormatter.format(value)})` : percentFormatter.format(value)
}

const integerPercentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  signDisplay: 'never',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export function formatIntegerPercent(value: number): string {
  return value < 0
    ? `(${integerPercentFormatter.format(value)})`
    : integerPercentFormatter.format(value)
}
