import { ComponentProps, FC, HTMLAttributes } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid'
import clsx from 'clsx'

export type AlertType = 'error' | 'warning' | 'success' | 'info' | 'default'

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  readonly type?: AlertType
  readonly title?: string
  readonly showIcon?: boolean
  readonly showDismiss?: boolean
  readonly onDismiss?: () => void
}

type AlertThemeColors = {
  readonly bg: string
  readonly title: string
  readonly text: string
  readonly icon: string
  readonly active: string
  readonly hover: string
}

/*
background: '#DDDDDD',
          highlighted: '#BBBBBB',
          default: '#333333',
          accent: '#444444',
*/

const ALERT_TYPES: Readonly<Record<AlertType, AlertThemeColors>> = {
  default: {
    bg: 'bg-alert-neutral-background',
    title: 'text-alert-neutral-accent',
    text: 'text-alert-neutral-default',
    icon: 'text-alert-neutral-accent',
    active: 'active:bg-alert-neutral-highlighted',
    hover: 'hover:bg-alert-neutral-highlighted',
  },
  info: {
    bg: 'bg-alert-info-background',
    title: 'text-alert-info-accent',
    text: 'text-alert-info-default',
    icon: 'text-alert-info-accent',
    active: 'active:bg-alert-info-highlighted',
    hover: 'hover:bg-alert-info-highlighted',
  },
  error: {
    bg: 'bg-alert-error-background',
    title: 'text-alert-error-accent',
    text: 'text-alert-error-default',
    icon: 'text-alert-error-accent',
    active: 'active:bg-alert-error-highlighted',
    hover: 'hover:bg-alert-error-highlighted',
  },
  success: {
    bg: 'bg-alert-success-background',
    title: 'text-alert-success-accent',
    text: 'text-alert-success-default',
    icon: 'text-alert-success-accent',
    active: 'active:bg-alert-success-highlighted',
    hover: 'hover:bg-alert-success-highlighted',
  },
  warning: {
    bg: 'bg-amber-50',
    title: 'text-amber-800',
    text: 'text-amber-700',
    icon: 'text-amber-400',
    active: 'active:text-amber-700',
    hover: 'hover:text-amber-700',
  },
}

const ALERT_ICONS: Readonly<Record<AlertType, FC<ComponentProps<'svg'>>>> = {
  default: InformationCircleIcon,
  info: InformationCircleIcon,
  error: XCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationTriangleIcon,
}

const Alert: FC<AlertProps> = ({
  className,
  children,
  showIcon,
  showDismiss,
  onDismiss,
  type = 'default',
  title,
}) => {
  const css = ALERT_TYPES[type]
  const Icon = ALERT_ICONS[type]

  return (
    <div className={clsx('rounded-md p-4', css.bg, className)} role="alert">
      <div className="flex">
        {showIcon && (
          <div className="mr-3 shrink-0" data-testid="alert-icon">
            <Icon className={clsx('h-5 w-5', css.icon)} aria-hidden="true" />
          </div>
        )}
        <div className="flex-1 space-y-2">
          {title && <h3 className={clsx('text-sm font-medium', css.title)}>{title}</h3>}
          {children && <div className={clsx('text-sm font-normal', css.text)}>{children}</div>}
        </div>
        {showDismiss && (
          <button
            className={clsx('ml-0 shrink-0 self-start rounded', css.active, css.hover)}
            onClick={onDismiss}
          >
            <XMarkIcon className={clsx('h-6 w-6', css.icon)} aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  )
}

export default Alert
