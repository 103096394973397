import { ComponentProps, FC, useState } from 'react'
import { CalendarIcon, CheckIcon } from '@heroicons/react/24/solid'
import { axios } from 'api/lib'
import { Button } from 'ui/src/components/forms'

interface EventAddToCalendarButtonProps extends ComponentProps<'div'> {
  readonly eventId: number
  readonly size: ComponentProps<typeof Button>['size']
}

export const AddToCalendarButton: FC<EventAddToCalendarButtonProps> = ({
  eventId,
  size,
  className,
}) => {
  const [isAddedToCalendar, setIsAddedToCalendar] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const addToCalendar = async (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    setIsDisabled(true)
    
    try {
      await axios.post(`/api/events/${eventId}/add-to-calendar`)
      setIsAddedToCalendar(true)
    } catch (error) {
      console.error(error)
    } finally {
      setIsDisabled(false)
    }
  }

  return (
    <>
      {isAddedToCalendar ? (
        <Button uiType="regular" className={className} size={size} disabled={true}>
          <span className="flex items-center justify-center sm:justify-start sm:self-start">
            <CheckIcon className="mr-2 h-4 w-4" />
            Added to calendar
          </span>
        </Button>
      ) : (
        <Button
          uiType="secondary"
          className={className}
          size={size}
          onClick={addToCalendar}
          disabled={isDisabled}
        >
          <span className="flex items-center justify-center sm:justify-start sm:self-start">
            <CalendarIcon className="mr-2 h-4 w-4" />
            Add to my calendar
          </span>
        </Button>
      )}
    </>
  )
}
