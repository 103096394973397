import { ReactElement } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { Button } from 'ui/src/components/forms'

interface SuccessCardProps {
  title: string | ReactElement
  dismissLabel: string
  onDismiss: () => void
}

export function SuccessCard({ title, dismissLabel, onDismiss }: SuccessCardProps) {
  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex justify-center">
        <div className="bg-success-card-green-300 text-success-card-green-800 w-fit rounded-full p-5">
          <CheckCircleIcon className="h-14 w-14" />
        </div>
      </div>
      <div className="text-deep-teal-500 text-center text-lg font-medium leading-6">{title}</div>
      <Button size="lg" uiType="primary" onClick={onDismiss}>
        {dismissLabel}
      </Button>
    </div>
  )
}
