import { ComponentProps, FC } from 'react'
import { Event } from 'api/cms'
import clsx from 'clsx'
import { Link } from 'ui/components/navigation'
import { EventCardCoverImage } from './EventCardCoverImage'

export interface CarouselEventCardProps extends ComponentProps<'div'> {
  readonly event: Event
}

export const CarouselEventCard: FC<CarouselEventCardProps> = ({ event, className, ...props }) => {
  return (
    <Link className={className} href={`/events/${event.id}`}>
      <div className="flex gap-4" {...props}>
        <div className="w-[72px] flex-shrink-0">
          <EventCardCoverImage event={event} />
        </div>
        <div className="flex flex-1 flex-col justify-between gap-4">
          <div className="flex flex-col gap-1">
            {event.title && (
              <div className={clsx('text-deep-teal-500 text-base font-semibold leading-tight')}>
                {event.title}
              </div>
            )}
            {event.subTitle && (
              <div className={clsx('text-deep-teal-400 text-sm leading-5')}>{event.subTitle}</div>
            )}
          </div>

          <div className="text-deep-teal-800 border-l-2 border-l-orange-500 pl-2 text-sm font-medium leading-4">
            {event.location || 'TBD'}
          </div>
        </div>
      </div>
    </Link>
  )
}
