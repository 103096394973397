import { ComponentPropsWithoutRef } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { cn } from 'ui/lib'

export default function ToggleButton({ className, ...props }: ComponentPropsWithoutRef<'button'>) {
  return (
    <button className={cn('text-deep-teal-100 -m-2.5 p-2.5', className)} {...props}>
      <span className="sr-only">Toggle sidebar</span>
      <Bars3Icon aria-hidden="true" className="size-6" />
    </button>
  )
}
