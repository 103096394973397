import { FC } from 'react'
import { Dialog, DialogProps } from 'ui/components/containers/Dialog'
import { Button } from 'ui/src/components/forms'

export const ErrorDialog: FC<Omit<DialogProps, 'actions' | 'open'>> = ({
  title,
  onClose,
  children,
  ...props
}) => {
  const DefaultActions = (
    <Button onClick={() => onClose()} size="xl">
      Close
    </Button>
  )
  return (
    <Dialog
      open={true}
      title={title || 'Something went wrong.'}
      actions={DefaultActions}
      onClose={onClose}
      {...props}
    >
      <div className="md:prose text-rangitoto-500 min-h-[120px] text-lg">{children}</div>
    </Dialog>
  )
}
