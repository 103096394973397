import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react'
import { cn } from 'ui/lib'
import { MenuItemType } from './data'
import MenuItem from './MenuItem'

export interface MenuProps extends ComponentPropsWithoutRef<'ul'> {
  readonly items?: MenuItemType[]
}

export default forwardRef(function Menu(
  { className, items, ...props }: MenuProps,
  ref: ForwardedRef<HTMLUListElement>,
) {
  return (
    <ul ref={ref} className={cn('space-y-2', className)} {...props}>
      {items?.map((item) => (
        <MenuItem
          key={item.name}
          name={item.name}
          href={item.href}
          icon={item.icon}
          items={item.items}
        />
      ))}
    </ul>
  )
})
