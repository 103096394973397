import { useMemo } from 'react'
import { debounce } from 'lodash'
import analytics from './analytics'

export const useDebouncedTrack = (timeout: number) => {
  return useMemo(
    () =>
      debounce((action: string, payload: Record<string, string | number | boolean>) => {
        analytics?.track(action, { action, ...payload })
      }, timeout),
    [timeout],
  )
}
