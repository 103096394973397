import { createContext } from 'react'

export interface GeolocationResult {
  readonly latitude: number
  readonly longitude: number
  readonly accuracy: number
}

export interface GeolocationService {
  getCurrentPosition(): Promise<GeolocationResult>
}

export const GeolocationContext = createContext<GeolocationService>({
  getCurrentPosition: () =>
    Promise.reject(new Error('Geolocation.getCurrentPosition() is not implemented.')),
})
