import { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { AlternativeInvestmentsChartDesktop } from './AlternativeInvestmentsChartDesktop'

export function AlternativeInvestmentsCardDesktop({
  className,
  ...props
}: ComponentPropsWithoutRef<'a'>) {
  return (
    <a
      className={clsx(
        'flex flex-col gap-6 rounded-lg bg-[#f0f0f0] p-4 shadow hover:shadow-xl',
        className,
      )}
      href="https://meetperry.com/mp-guide-to-alternative-investments"
      target="_blank"
      data-trackclick={JSON.stringify({
        action: 'Taxonomy Viewed',
      })}
      {...props}
    >
      <h3 className="font-brand text-deep-teal-500 text-2xl font-medium leading-tight">
        {`Introducing meetperry's Guide to Alternative Investments`}
      </h3>

      <div className="flex flex-1 items-center justify-center">
        <AlternativeInvestmentsChartDesktop className="h-full max-h-64 max-w-64" />
      </div>
    </a>
  )
}
