const TRILLION = Math.pow(10, 12)
const BILLION = Math.pow(10, 9)
const MILLION = Math.pow(10, 6)
const THOUSAND = Math.pow(10, 3)

function divideAndRound(value: number, divider: number): number {
  return Math.round((value * 10) / divider) / 10
}

export function formatMoneyShort(value: number): string {
  if (value >= TRILLION) {
    return `$${divideAndRound(value, TRILLION)}T`
  }
  if (value >= BILLION) {
    return `$${divideAndRound(value, BILLION)}B`
  }
  if (value >= MILLION) {
    return `$${divideAndRound(value, MILLION)}M`
  }
  if (value >= THOUSAND) {
    return `$${divideAndRound(value, THOUSAND)}K`
  }
  return '$' + value
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export function formatMoney(value: number): string {
  return currencyFormatter.format(value)
}
