import { FC } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { Card } from 'ui/components/content'

export const ErrorComponent: FC = () => {
  return (
    <Card className="flex justify-center">
      <div className="flex max-w-[260px] flex-col">
        <div className="flex items-center gap-2">
          <ExclamationTriangleIcon className="text-deep-teal-100 h-8 w-8" />
          <h1 className="text-base font-semibold text-orange-600">Something went wrong</h1>
        </div>

        <div className="text-deep-teal-500 mt-2 text-sm font-normal">
          <span>Please check back in a few minutes or contact </span>
          <a
            className="hover:text-deep-teal-300 inline underline"
            href="mailto:concierge@meetperry.com"
          >
            concierge@meetperry.com
          </a>
        </div>
      </div>
    </Card>
  )
}
