import { ComponentPropsWithoutRef, useState } from 'react'
import Image from 'next/image'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'
import { extraNavigation, mainNavigation } from './data'
import Menu from './Menu'
import MenuItem from './MenuItem'
import MenuItemTransition from './MenuItemTransition'
import { SidebarContext } from './SidebarContext'
import ToggleButton from './ToggleButton'

export type DesktopNavProps = ComponentPropsWithoutRef<'div'> & {
  onOpen?: (open: boolean) => void
  hideNavigation?: boolean
}

export default function SidebarDesktop({
  hideNavigation,
  className,
  onOpen,
  ...props
}: DesktopNavProps) {
  const [open, setOpen] = useState(false)
  const logoutItem = extraNavigation.find((item) => item.name?.toLowerCase() === 'logout')

  const toggleSidebar = (value?: boolean) => {
    setOpen(value ?? !open)
    onOpen?.(value ?? !open)
  }

  return (
    <SidebarContext.Provider
      value={{
        isOpen: open,
        isClosed: !open,
        openSidebar: () => toggleSidebar(true),
        closeSidebar: () => toggleSidebar(false),
      }}
    >
      <div
        className={cn(
          'hidden transition-all ease-in-out lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col',
          open ? 'lg:w-72' : 'lg:w-16',
          className,
        )}
        {...props}
      >
        <div
          className="bg-deep-teal-500 flex grow flex-col px-4 py-6"
          onClick={() => toggleSidebar()}
        >
          <div className="flex items-center gap-5 px-1">
            <ToggleButton onClick={() => toggleSidebar()} />

            <MenuItemTransition show={open}>
              <Image src="/images/meetperry-white.svg" width={121} height={24} alt="" />
            </MenuItemTransition>
          </div>

          {hideNavigation ? (
            <div className="flex-1" />
          ) : (
            <Menu className="mt-14 flex-1" items={mainNavigation} />
          )}

          <hr className="my-2 border-white/40" />

          {!hideNavigation && <Menu items={extraNavigation} />}

          {hideNavigation && logoutItem && (
            <MenuItem name={logoutItem.name} href={logoutItem.href} icon={logoutItem.icon} />
          )}

          <div className="mt-20 flex gap-4">
            <div className={cn('relative h-4 min-h-6 min-w-8')}>
              <Link href="/">
                <Image
                  src="/images/meetperry-mobile-white.svg"
                  className="object-contain"
                  fill={true}
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </SidebarContext.Provider>
  )
}
