import { ComponentProps, FC } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import { MinusCircleIcon } from '@heroicons/react/24/outline'
import { InvestmentOpportunityWithTotalIoi } from 'api/dto'
import clsx from 'clsx'
import { Link } from 'ui/components/navigation'
import { formatMoneyShort } from 'ui/src/lib/formatters/moneyFormatter'
import { MarkdownProse } from '../MarkdownProse'

export interface InvestmentFullSizeCardProps extends ComponentProps<'a'> {
  readonly investment: Pick<
    InvestmentOpportunityWithTotalIoi,
    'id' | 'totalIOIs' | 'state' | 'title' | 'fundType' | 'insight' | 'sponsor'
  >
}

export const InvestmentFullSizeCard: FC<InvestmentFullSizeCardProps> = ({
  className,
  investment,
  ...props
}) => {
  return (
    <Link className={className} href={`/investments/${investment.id}`} {...props}>
      <div
        className={clsx(
          'border-deep-teal-100 flex h-full flex-col justify-center gap-3 rounded-lg border px-4 py-6',
          'hover:bg-deep-teal-500 hover:border-deep-teal-500 group',
        )}
      >
        <div className="flex w-full items-start justify-between">
          <img
            className="h-9 w-9 shrink-0 grow-0 object-contain"
            src={investment.sponsor?.avatar?.url}
          />
          <div>
            <IOBadge className="text-[#426F8D] group-hover:text-white">
              {investment.totalIOIs && (
                <span>{`${formatMoneyShort(investment.totalIOIs)} in IOIs`}</span>
              )}
              <State className="shrink-0 grow-0 justify-end" state={investment.state} />
            </IOBadge>
          </div>
        </div>
        <div className="flex-1 gap-y-1">
          <div className="text-deep-teal-500 h-10 text-base font-semibold leading-5 group-hover:text-white">
            {investment.title}
          </div>
          <div className="text-deep-teal-400 h-4 text-xs group-hover:text-white">
            {investment.fundType}
          </div>
        </div>
        {investment.insight ? (
          <Insight className="self-start" insight={investment.insight} />
        ) : (
          // Insight placeholder
          <div className="h-5"></div>
        )}
      </div>
    </Link>
  )
}

export interface StateProps extends ComponentProps<'div'> {
  readonly state?: string
}

export const State: FC<StateProps> = ({ state, className, ...props }) => {
  return (
    <div className={clsx('flex items-center gap-1', className)} {...props}>
      {state === 'new' ? (
        <>
          <StarIcon
            data-testid="star-icon"
            className="text-navy-400 h-3 w-3 group-hover:text-white"
          />
          <span className="text-navy-400 text-xs font-semibold group-hover:text-white">NEW</span>
        </>
      ) : state === 'closed' ? (
        <>
          <MinusCircleIcon
            data-testid="minus-circle-icon"
            className="text-taupe-700 h-3 w-3 group-hover:text-white"
          />
          <span className="text-taupe-700 text-xs font-semibold group-hover:text-white">
            CLOSED
          </span>
        </>
      ) : (
        <>{/* Intentionally left empty */}</>
      )}
    </div>
  )
}

interface InsightProps extends ComponentProps<'div'> {
  readonly insight?: string
}

export const Insight: FC<InsightProps> = ({ insight, className, ...props }) => {
  return (
    <MarkdownProse
      className={clsx(
        'text-deep-teal-500 bg-deep-teal-50 inline rounded px-2.5 py-0.5 text-xs font-semibold',
        className,
      )}
      markdown={insight}
      {...props}
    />
  )
}

export const IOBadge: FC<ComponentProps<'div'>> = ({ children, className, ...props }) => {
  return (
    <div
      className={clsx(
        'border-r-2 border-r-orange-500 pr-2 text-right text-xs font-semibold leading-4',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
