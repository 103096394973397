import { FC } from 'react'
import Link, { LinkProps } from 'next/link'

interface LogoProps extends Omit<LinkProps, 'href'> {
  link: string
  className?: string
}

export const Logo: FC<LogoProps> = ({ link, ...props }) => {
  return (
    <Link href={link} {...props}>
      <img className="h-6 w-[122px]" src="/images/meetperry-taupe.svg" />
    </Link>
  )
}
