import { FC, PropsWithChildren } from 'react'

import { PlatformComponentsContext } from 'ui/components/platform'
import { Hint } from 'ui/src/components/forms'
import { Prose } from '../../content/Prose'
import { Prompt } from '../../navigation'

export const PlatformComponentsProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <PlatformComponentsContext.Provider
      value={{
        tooltip: Hint,
        prompt: Prompt,
        prose: Prose,
      }}
    >
      {children}
    </PlatformComponentsContext.Provider>
  )
}
