import { FC } from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import clsx from 'clsx'
import { Label, Error } from 'ui/src/components/forms/Field'
import { AvatarInput, AvatarInputProps } from './AvatarInput'
import { RemovableAvatar } from './RemovableAvatar'

export interface AvatarFieldProps extends AvatarInputProps {
  readonly label?: string
  readonly error?: Pick<FieldError, 'message'>
  readonly hideError?: boolean
  readonly control: Control<any>
  readonly placeholder?: string
}

export const AvatarField: FC<AvatarFieldProps> = ({
  name,
  label,
  control,
  error,
  disabled,
  hideError,
  className,
  placeholder,
}) => {
  const wrapperClassName = clsx('block w-full', { 'opacity-25': disabled }, className)

  const inputWrapperClassName = clsx('flex cursor-pointer', { 'mt-1': label })

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const avatarUrl = !error && field.value?.url
        return (
          <div className={wrapperClassName}>
            {label && <Label name={name}>{label}</Label>}

            <label className={inputWrapperClassName}>
              <RemovableAvatar
                src={avatarUrl}
                onRemove={() => {
                  field.onChange(null)
                }}
              />
              <AvatarInput
                name={field.name}
                onChange={field.onChange}
                disabled={disabled}
                placeholder={placeholder}
                error={error}
              />
            </label>

            {!hideError && (
              <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
                {error?.message}
              </Error>
            )}
          </div>
        )
      }}
    />
  )
}
