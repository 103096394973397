import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import clsx from 'clsx'
import { Error } from 'ui/src/components/forms'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly label?: string | ReactNode
  readonly error?: Pick<FieldError, 'message'>
  readonly hideError?: boolean
  readonly inputClasses?: string
}

// eslint-disable-next-line react/display-name
export const Checkbox = forwardRef<any, CheckboxProps>(
  (
    {
      name,
      label,
      hideError,
      className,
      error,
      disabled,
      inputClasses = 'text-orange-500 focus:ring-orange-500',
      ...props
    },
    ref,
  ) => {
    return (
      <div className="flex flex-col">
        <div className={clsx({ 'opacity-25': disabled }, className, 'flex')}>
          <input
            type="checkbox"
            className={clsx('h-5 w-5 cursor-pointer rounded border-gray-300', inputClasses)}
            disabled={disabled}
            name={name}
            id={name}
            ref={ref}
            {...props}
          />

          {label && (
            <label htmlFor={name} className="ml-2 cursor-pointer">
              {label}
            </label>
          )}
        </div>
        {!hideError && (
          <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
            {error?.message}
          </Error>
        )}
      </div>
    )
  },
)
