import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { Card } from 'ui/components/content'
import { Link } from 'ui/components/navigation'

export const EventsCardEmpty: FC<ComponentProps<'div'>> = ({ className, ...props }) => (
  <Card className={clsx(className)} {...props}>
    <div className="flex gap-2">
      <Logo className="h-8 w-8 shrink-0" />
      <div className="flex-col text-left">
        <h3 className="text-deep-teal-500 mb-1 text-base font-medium leading-5">
          There are no events scheduled
        </h3>
        <p className="text-deep-teal-400 text-sm font-normal leading-5">
          Please check back soon, to see previous events{' '}
          <Link
            href="/events?status=past"
            className="font-semibold text-orange-600 hover:text-orange-800"
          >
            click here
          </Link>
          .
        </p>
      </div>
    </div>
  </Card>
)

const Logo: FC<ComponentProps<'svg'>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <rect width="32" height="32" rx="4" fill="white" />
    <path
      d="M9 7V11M7 9H11M10 21V25M8 23H12M17 7L19.2857 13.8571L25 16L19.2857 18.1429L17 25L14.7143 18.1429L9 16L14.7143 13.8571L17 7Z"
      stroke="#CBCECA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
