import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { first } from 'lodash'

export const useActiveChild = <T extends Element>(): {
  containerRef: RefObject<T>
  activeChildId: string | null
} => {
  const containerRef = useRef<Element>()
  const [activeChildId, setActiveChildId] = useState('')

  const bindWindowScrollHandler = useCallback(() => {
    windowScrollHandler(containerRef as any, activeChildId, setActiveChildId)
  }, [containerRef, activeChildId])

  useEffect(() => {
    document.addEventListener('scroll', bindWindowScrollHandler)

    return () => {
      document.removeEventListener('scroll', bindWindowScrollHandler)
    }
  }, [bindWindowScrollHandler])

  useEffect(() => {
    bindWindowScrollHandler()
  }, [containerRef?.current])

  return { containerRef: containerRef as any, activeChildId }
}

export default useActiveChild

function windowScrollHandler(
  containerRef: RefObject<Element>,
  activeChildId: string,
  setActiveChildId: Dispatch<SetStateAction<string>>,
) {
  if (!containerRef?.current?.children?.length && activeChildId) {
    setActiveChildId(null!)
    return
  }

  const childrenArray = Array.from(containerRef.current?.children!)

  const activeChild = childrenArray.reduce<Element | null>((topChild, child) => {
    if (!topChild) {
      return child
    }
    const topChildBoundariesRect = topChild?.getBoundingClientRect()
    const childBoundariesRect = child?.getBoundingClientRect()
    if (
      topChildBoundariesRect.bottom < 0 ||
      (childBoundariesRect.bottom > 0 && childBoundariesRect.bottom < topChildBoundariesRect.bottom)
    ) {
      return child
    }
    return topChild
  }, first(childrenArray)!)

  setActiveChildId(activeChild?.id!)
}
