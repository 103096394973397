import { ComponentProps, FC, ReactNode } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import CarouselNuka, { CarouselProps, ControlProps } from 'nuka-carousel'

interface Props extends ComponentProps<'div'> {
  slidesToShow?: number
  beforeSlide?: CarouselProps['beforeSlide']
}

export const Carousel: FC<Props> = ({
  className,
  children,
  slidesToShow,
  beforeSlide,
  ...props
}) => {
  return (
    <div
      className={clsx(
        'pb-9' /* compensates negative margin for slider controls, see comments below */,
        className,
      )}
      {...props}
    >
      <CarouselNuka
        renderBottomCenterControls={Pagination}
        renderBottomRightControls={ButtonNext}
        renderBottomLeftControls={ButtonPrev}
        slidesToShow={slidesToShow}
        beforeSlide={beforeSlide}
        defaultControlsConfig={{
          containerClassName: 'h-5 -mb-9', // 20px height of controls + 16px top margin
          nextButtonClassName: 'hidden',
          prevButtonClassName: 'hidden',
        }}
        speed={200}
      >
        {children}
      </CarouselNuka>
    </div>
  )
}

const ButtonPrev: (p: ControlProps) => ReactNode = ({ previousDisabled, previousSlide }) => {
  return (
    <button data-testid="btn-prev" onClick={previousSlide} disabled={previousDisabled}>
      <ChevronLeftIcon
        className={clsx('h-5 w-5', {
          'text-deep-teal-100': previousDisabled,
          'text-deep-teal-600': !previousDisabled,
        })}
      />
    </button>
  )
}

const ButtonNext: (p: ControlProps) => ReactNode = ({ nextDisabled, nextSlide }) => {
  return (
    <button data-testid="btn-next" onClick={nextSlide} disabled={nextDisabled}>
      <ChevronRightIcon
        className={clsx('h-5 w-5', {
          'text-deep-teal-100': nextDisabled,
          'text-deep-teal-600': !nextDisabled,
        })}
      />
    </button>
  )
}

const Pagination: (p: ControlProps) => ReactNode = ({
  pagingDotsIndices,
  goToSlide,
  currentSlide,
}) => {
  return (
    <div className="flex h-5 items-center justify-center gap-4">
      {pagingDotsIndices.map((index) => (
        <div
          key={index}
          onClick={() => goToSlide(index)}
          className={clsx('h-2 rounded-full transition-all', {
            'w-6 bg-orange-600': index === currentSlide,
            'bg-deep-teal-100 w-2': index !== currentSlide,
          })}
        />
      ))}
    </div>
  )
}
