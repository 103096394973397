import { FC } from 'react'
import { Nominator } from 'api/dto'
import { AmityAvatar } from 'ui/components/content'
import { Link } from 'ui/components/navigation'
import { useRoutingContext } from 'ui/lib/navigation'

export const NominatorElement: FC<{ nominator: Nominator; rank?: number }> = ({
  nominator: { numberOfJoinedNominations: count, firstName, lastName, avatarFileId, userId },
  rank,
}) => {
  const { baseUrl } = useRoutingContext()
  return (
    <Link href={`${baseUrl}/members/${userId}`} className="cursor-pointer">
      <div className="flex gap-x-4 py-2">
        <AmityAvatar className="h-11 w-11" fileId={avatarFileId!} />
        <div className="flex flex-col gap-y-1">
          <h4 className="text-deep-teal-500 text-base font-semibold">{`${firstName} ${lastName}`}</h4>
          <div className="text-deep-teal-300 text-sm leading-5">
            {`${count} nomination${count > 1 ? 's' : ''}`}
          </div>
        </div>
        {rank && (
          <div data-testid="rank-element" className="flex flex-1 items-center justify-end">
            <Rank rank={rank} />
          </div>
        )}
      </div>
    </Link>
  )
}

const Rank = ({ rank }: { rank: number }) => (
  <div className="bg-deep-teal-400 h-6 w-6 rounded-lg text-center text-xs font-semibold leading-6 text-white">{`#${rank}`}</div>
)
