import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { Link } from 'ui/components/navigation'

export const SignupHelpHint: FC<ComponentProps<'div'>> = ({ className, ...rest }) => {
  return (
    <div className={clsx('mx-auto flex flex-col items-center', className)} {...rest}>
      <div className="text-taupe-500 text-sm leading-5">Need help? Please contact</div>
      <a
        className="text-sm font-semibold leading-5 text-orange-400 hover:text-orange-200"
        target="_blank"
        href="mailto:members@meetperry.com"
        rel="noreferrer"
      >
        members@meetperry.com
      </a>
      <Link
        className="mt-5 block text-sm font-semibold leading-5 text-orange-400 hover:text-orange-200"
        href="/logout"
      >
        Click here to logout
      </Link>
    </div>
  )
}
