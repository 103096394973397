import { ComponentProps, FC, forwardRef } from 'react'
import { PlatformProse } from 'ui/components/platform'
import { useMarkdown } from 'ui/src/lib/markdown'

interface MarkdownProseProps extends ComponentProps<'div'> {
  readonly markdown?: string
}

// eslint-disable-next-line react/display-name
export const MarkdownProse: FC<MarkdownProseProps> = forwardRef(({ markdown, ...props }, ref) => {
  const parsedMarkdownString = useMarkdown(markdown || '', { sanitize: false })

  return <PlatformProse contentString={parsedMarkdownString} ref={ref} {...props} />
})
