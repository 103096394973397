import { Event } from 'api/cms'
import { EventCardDate } from './EventCardDate'

export const EventCardCoverImage = ({ event }: { event: Event }) => {
  return (
    <div className="border-deep-teal-100 flex flex-col overflow-hidden rounded-lg border">
      {event.image ? (
        <img className="aspect-square w-full" src={event.image.url} />
      ) : (
        <div className="bg-taupe-500 flex aspect-square w-full flex-col justify-end overflow-hidden">
          <svg
            data-testid="event-card-cover-image-svg"
            className="text-deep-teal-500"
            width="72"
            height="57"
            viewBox="0 0 72 57"
            fill="none"
          >
            <path
              d="M72 47.982V57.003H0V48.015C4.18785 42.4184 9.62372 37.876 15.8754 34.7491C22.127 31.6223 29.022 29.9972 36.012 30.003C50.724 30.003 63.792 37.065 72 47.982ZM48.006 12C48.006 15.1826 46.7417 18.2348 44.4913 20.4853C42.2408 22.7357 39.1886 24 36.006 24C32.8234 24 29.7712 22.7357 27.5207 20.4853C25.2703 18.2348 24.006 15.1826 24.006 12C24.006 8.8174 25.2703 5.76515 27.5207 3.51472C29.7712 1.26428 32.8234 0 36.006 0C39.1886 0 42.2408 1.26428 44.4913 3.51472C46.7417 5.76515 48.006 8.8174 48.006 12Z"
              fill="currentColor"
            />
          </svg>
        </div>
      )}

      <EventCardDate event={event} />
    </div>
  )
}
