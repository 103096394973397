import { ComponentProps, FC } from 'react'
import Link from 'next/link'
import { SparklesIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

export const EventsEmptyState: FC<ComponentProps<'div'>> = ({className, ...props}) => (
  <div className={clsx('flex items-top gap-3', className)} {...props}>
    <SparklesIcon className="w-6 h-6 shrink-0 text-deep-gray-100" />

    <div className="flex-col text-left">
      <h3 className="text-deep-teal-900 basic-text-sm leading-1 font-semibold">
        There are no events scheduled
      </h3>
      <p className="text-deep-teal-400 text-sm leading-5 font-normal">
        Please check back soon, to see previous events{' '}
        <Link
          href="/events?status=past"
          className="text-orange-600 hover:text-orange-800"
        >
          click&nbsp;here
        </Link>
        .
      </p>
    </div>
  </div>
)