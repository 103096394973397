import { useContext } from 'react'
import { useAxios, useAxiosInfinite } from 'api/lib'
import { AxiosRequestConfig } from 'axios'
import qs from 'qs'
import { SWRConfiguration, SWRResponse } from 'swr'
import { SWRInfiniteConfiguration, SWRInfiniteResponse } from 'swr/infinite'
import { AmityContext } from './AmityProvider'
import { Paging } from '../types'

/**
 * Wraps `useAxios`. Adds Amity access token to Authorization header.
 * Encodes query params according to Amity API requirements.
 * Postpones request until the access token is available.
 */
export function useAmityApi<Data = unknown, Error = unknown>(
  url: string,
  swrConfig?: SWRConfiguration,
  axiosConfig?: AxiosRequestConfig,
): SWRResponse<Data, Error> {
  const baseURL = process.env.NEXT_PUBLIC_AMITY_API_URL
  const { params, ...axiosConfigWithoutParams } = axiosConfig || {}
  const encodedParams = params ? `?${qs.stringify(axiosConfig?.params)}` : ''
  const { isAuthorized } = useContext(AmityContext)

  return useAxios<Data, Error>(isAuthorized ? url + encodedParams : null, swrConfig, {
    ...axiosConfigWithoutParams,
    baseURL,
    headers: {
      ...axiosConfig?.headers,
    },
  })
}

/**
 * Helper methods that custom infinite hooks returns to handle pagination.
 */
type Pagination = {
  loadNext: () => void
  hasNext: boolean
}

/**
 * Response object that `useAmityApiInfinite` returns.
 * Extends `SWRInfiniteResponse` with Amity-specific pagination properties.
 */
export type InfiniteResponse<Data, Error = unknown> = SWRInfiniteResponse<Data, Error> & Pagination

/**
 * Wraps `useAxiosInfinite`. Adds Amity access token to Authorization header.
 * Encodes query params according to Amity API requirements.
 * Postpones request until the access token is available.
 */
export function useAmityApiInfinite<Data extends Paging, Error = unknown>(
  url: string | null,
  swrConfig?: SWRInfiniteConfiguration,
  axiosConfig?: AxiosRequestConfig,
): InfiniteResponse<Data, Error> {
  const { isAuthorized } = useContext(AmityContext)
  const { params = {}, ...axiosConfigWithoutParams } = axiosConfig || {}
  const keyLoader = getKey.bind(null, url, qs.stringify(params || {}, { arrayFormat: 'brackets' }))

  const response = useAxiosInfinite<Data, Error>(isAuthorized ? keyLoader : () => null, swrConfig, {
    ...axiosConfigWithoutParams,
    baseURL: process.env.NEXT_PUBLIC_AMITY_API_URL,
    headers: {
      ...axiosConfig?.headers,
    },
  })

  return {
    ...response,
    hasNext: !!response.data?.[response.data.length - 1]?.paging?.next,
    loadNext: () => response.setSize((size) => size + 1),
  }
}

/**
 * Generates the key for `useAxiosInfinite`.
 * Encodes query params according to Amity API requirements.
 * Adds the token (cursor) to the API endpoint.
 */
export function getKey<T extends Paging | null>(
  path: string | null,
  params: string,
  pageIndex: number,
  previousPageData: T,
) {
  // Reached the end
  if (previousPageData && !previousPageData.paging?.next) {
    return null
  }

  // First page, we don't have `previousPageData`
  if (pageIndex === 0) {
    return path + `?${params}`
  }

  // Add the token (cursor) to the API endpoint.
  const options = qs.stringify({
    options: { token: previousPageData?.paging?.next },
  })
  return path + (params ? `?${params}&${options}` : `?${options}`)
}
