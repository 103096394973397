import { ChangeEventHandler, ComponentProps, FC, useEffect, useState } from 'react'
import { Combobox } from '@headlessui/react'
import clsx from 'clsx'
import { AutocompleteOptions } from './AutocompleteOptions'

export interface AutocompleteInputProps extends ComponentProps<'div'> {
  readonly name: string
  readonly selectedValue: string
  readonly autocompleteValues?: string[]
  readonly placeholder?: string
  readonly disabled?: boolean
  readonly hasError?: boolean
  readonly onSelectedValueChange: (value: string) => void
  readonly onChange?: ChangeEventHandler<HTMLInputElement>
}

export const AutocompleteInput: FC<AutocompleteInputProps> = ({
  className,
  name,
  autocompleteValues = [],
  placeholder,
  selectedValue = '',
  disabled,
  hasError,
  onSelectedValueChange,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    setInputValue(selectedValue)
  }, [selectedValue])

  const hasAutocomplete = !!autocompleteValues?.length || inputValue?.trim().length > 0

  // handlers

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value || ''

    // handle case if selected in autocomplete value was changed manually
    // also will trigger new autocomplete suggestions search
    handleSelectedValueChange(value)

    onChange?.(e)
  }

  const handleSelectedValueChange = (value: string) => {
    // should not trim value because user may type value with whitespaces
    setInputValue(value)
    // should be trimmed as output value
    onSelectedValueChange(value.trim())
  }

  return (
    <div className={clsx('flex w-full flex-col items-center gap-2 sm:flex-row', className)}>
      <div className="w-full">
        <Combobox value={inputValue} onChange={handleSelectedValueChange} disabled={disabled}>
          {({ open }) => {
            return (
              <div className="relative">
                <div
                  className={clsx(
                    'bg-white',
                    'relative flex w-full flex-wrap gap-x-1 gap-y-2 px-3 py-2', // box model
                    'border-taupe-600 rounded-md border shadow-sm', // border and shadow
                    'hover:border-taupe-700', // hover
                    'focus-within:!border-navy-500', // focus
                    'cursor-default overflow-hidden',
                    hasError &&
                      'border-pure-red hover:border-pure-red focus-within:!border-pure-red',
                  )}
                >
                  <Combobox.Input
                    className={clsx(
                      'inline-block grow px-0 py-0', // box model
                      'text-sm font-normal leading-5', // text
                      'border-none focus:ring-0', // border,
                    )}
                    value={inputValue}
                    data-testid={`combobox-${name}`}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                  />
                </div>
                {hasAutocomplete && (
                  <AutocompleteOptions
                    inputValue={inputValue}
                    open={open && inputValue?.length > 0}
                    options={autocompleteValues}
                  />
                )}
              </div>
            )
          }}
        </Combobox>
      </div>
    </div>
  )
}
