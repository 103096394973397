import { FC, PropsWithChildren, useEffect } from 'react'
import Link from 'next/link'
import { Router, useRouter } from 'next/router'
import { RoutingContextProvider, useRoutingContext } from 'ui/lib/navigation/'
import { RouterEvents } from 'ui/lib/navigation/RoutingContext'

export const RoutingProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()
  const { events } = useRoutingContext()

  const handleRouteChange = (url: string) => {
    events.emit('navigated', {
      type: 'navigated',
      context: { pathname: url },
    } as RouterEvents)
  }

  useEffect(() => {
    Router.events.on('routeChangeComplete', handleRouteChange)

    // Clean up the listener when the component is unmounted
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <RoutingContextProvider
      value={{
        pathname: router.pathname,
        query: router.query as { [key: string]: string | string[] },
        push: (url, options) => router.push(url, undefined, options),
        replace: (url, options) => router.replace(url, undefined, options),
        events,
        baseUrl: '',
        // Next.js Link component have to has href prop
        LinkComponent: ({ href = '#', ...rest }) => <Link href={href} {...rest} />,
      }}
    >
      {children}
    </RoutingContextProvider>
  )
}
