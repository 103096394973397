import { ComponentType, createContext } from 'react'
import { PlatformPromptProps } from './PlatformPromptComponent'
import { PlatformProseProps } from './PlatformProseComponent'
import { PlatformTooltipProps } from './PlatformTooltipComponent'

export type PlatformComponentsContextValue = {
  prompt: ComponentType<PlatformPromptProps>
  tooltip: ComponentType<PlatformTooltipProps>
  prose: ComponentType<PlatformProseProps>
}

export const PlatformComponentsContext = createContext<PlatformComponentsContextValue>({
  prompt: () => {
    throw new Error('PlatformPrompt is not provided by PlatformComponentsContext')
  },

  tooltip: () => {
    throw new Error('PlatformTooltip is not provided by PlatformComponentsContext')
  },

  prose: () => {
    throw new Error('PlatformProse is not provided by PlatformComponentsContext')
  },
})
