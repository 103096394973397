import { useMemo } from 'react'
import { EventWithCalendarStartDate } from 'api/dto'
import { eventDateFormatter } from '../eventDateFormatter'

export const useEventCardDate = (event: EventWithCalendarStartDate): string => {
  return useMemo(() => {
    if (event.eventDateTbd) {
      return event.eventDateTbdText || 'Date: TBD'
    }

    return eventDateFormatter(event).full
  }, [event])
}
