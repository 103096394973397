import { ComponentProps, FC, ReactElement } from 'react'
import { getFileDownloadUrl } from 'amity/api'
import { Avatar } from './index'

type AmityAvatarProps = Pick<ComponentProps<'div'>, 'className'> & {
  fileId?: string | null
  fallback?: ReactElement
}

export const AmityAvatar: FC<AmityAvatarProps> = ({ className, fileId, fallback }) => {
  const amityAvatarUrl = fileId && getFileDownloadUrl(fileId)

  return <Avatar className={className} src={amityAvatarUrl} fallback={fallback} />
}
