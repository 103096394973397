import { useCallback, useEffect } from 'react'
import { UserPhoneNumberConsent } from 'api/dto'
import { useShowingInterval } from 'ui/lib'
import { useSmsReceivingConsentDialogStore } from './useSmsReceivingConsentDialogStore'

const SHOWING_TIMER_PERIOD_MILLISECONDS =
  24 /* hours */ * 60 /* minutes */ * 60 /* seconds */ * 1000 /* milliseconds */

export function useSmsReceivingConsentDialogShowing() {
  const { userPhoneNumber, state, load } = useSmsReceivingConsentDialogStore()

  const { isShown, show, stopShowing, showLater } = useShowingInterval({
    id: 'phone-number-consent',
    period: SHOWING_TIMER_PERIOD_MILLISECONDS,
  })

  // initial loading
  useEffect(() => {
    if (!userPhoneNumber && state === 'sms-receiving-consent-dialog') {
      load()
    }
  }, [state, load])

  // check wether show dialog or not
  useEffect(() => {
    if (state === 'sms-receiving-consent-dialog/loaded') {
      if (!userPhoneNumber || userPhoneNumber.consent === UserPhoneNumberConsent.UNKNOWN) {
        show()
      } else {
        stopShowing()
      }
    }
  }, [show, stopShowing, userPhoneNumber])

  const close = useCallback(() => {
    if (
      state === 'sms-receiving-consent-dialog/submitted' ||
      state === 'sms-receiving-consent-dialog/dismissed'
    ) {
      stopShowing()
    } else {
      showLater()
    }
  }, [state, stopShowing, showLater])

  return {
    isShown,
    show,
    close,
  }
}
