import { ComponentProps, FC } from 'react'
import clsx from 'clsx'

export const InvestmentFullSizeCardLoading: FC<ComponentProps<'div'>> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        'flex animate-pulse flex-col gap-3 rounded-lg border border-slate-200 px-4 py-6',
        className,
      )}
      {...props}
    >
      <div className="h-9 w-9 rounded-full bg-slate-200" />

      <div>
        <div className="h-4 w-44 bg-slate-200" />

        <div className="mt-7 h-4 w-44 bg-slate-200" />
      </div>

      <div className="h-4 w-full bg-slate-200" />
    </div>
  )
}
