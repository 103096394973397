import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { Card } from 'ui/components/content/Card'

export const EventCardLoading: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  return (
    <Card className={clsx('flex animate-pulse gap-4', className)} {...props}>
      <div className="h-9 w-9 shrink-0 self-start rounded-full bg-slate-200" />

      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col gap-2">
          <div className="h-4 w-full bg-slate-200" />
          <div className="h-4 w-full bg-slate-200" />
        </div>

        <div className="h-4 w-full bg-slate-200" />
      </div>
    </Card>
  )
}
