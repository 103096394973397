import { FC, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import type { PlatformPromptProps } from 'ui/components/platform'

export const Prompt: FC<PlatformPromptProps> = ({ when, children }) => {
  const [url, setUrl] = useState<string>()
  const [renderChildren, setRenderChildren] = useState(false)
  const [forceNavigation, setForceNavigation] = useState(false)

  const cancelNavigation = useCallback(() => setRenderChildren(false), [])

  const proceedWithoutAction = useCallback((callback?: () => void) => {
    callback?.()
    setForceNavigation(true)
  }, [])

  const executeAndProceed = useCallback(async (callback: () => Promise<void>) => {
    await callback()
    setForceNavigation(true)
  }, [])

  const router = useRouter()

  const onRouteChangeStart = useCallback(
    (nextUrl: string) => {
      setUrl(nextUrl)

      if (forceNavigation) {
        return
      }

      if (when) {
        setRenderChildren(true)

        router.events.emit('routeChangeComplete', router.pathname)
        throw 'Abort route change. Please ignore this error.'
      }
    },
    [when, router, forceNavigation],
  )

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteChangeStart)
    return () => router.events.off('routeChangeStart', onRouteChangeStart)
  }, [onRouteChangeStart])

  const navigate = useCallback(() => {
    url && router.push(url)
  }, [url, router])

  useEffect(() => navigate(), [forceNavigation])

  const resolvedChildren =
    typeof children === 'function'
      ? children({ cancelNavigation, proceedWithoutAction, executeAndProceed })
      : children

  return <>{renderChildren && resolvedChildren}</>
}
