import { MouseEventHandler } from 'react'
import { useForm } from 'react-hook-form'
import { UserPhoneNumberConsent } from 'api/dto'
import { useSession } from 'auth'
import { cn } from 'ui/lib'
import { Button, PhoneField } from 'ui/src/components/forms'
import { useYup } from 'ui/src/lib'
import { userPhoneNumberSchema } from './userPhoneNumberSchema'
import { useSmsReceivingConsentDialogStore } from './useSmsReceivingConsentDialogStore'

export interface SmsReceivingConsentForm {
  phoneNumber: string
  consent: UserPhoneNumberConsent
}

interface SmsReceivingConsentFormProps {
  className?: string
  onDismiss: () => void
}

export function SmsReceivingConsentForm({ className, onDismiss }: SmsReceivingConsentFormProps) {
  const { user } = useSession()
  const { userPhoneNumber, submit, state } = useSmsReceivingConsentDialogStore()

  const isDisabled = state === 'sms-receiving-consent-dialog/submitting'

  const resolver = useYup(userPhoneNumberSchema)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SmsReceivingConsentForm>({
    mode: 'onChange',
    defaultValues: {
      phoneNumber: userPhoneNumber?.phoneNumber || '',
    },
    resolver,
  })

  const onDismissClickHandler: MouseEventHandler = async (e) => {
    e.preventDefault()
    await submit({
      phoneNumber: userPhoneNumber?.phoneNumber || '',
      consent: UserPhoneNumberConsent.DECLINED,
    })
    onDismiss()
  }

  return (
    <form
      className={cn('flex flex-col gap-y-5', className)}
      onSubmit={handleSubmit((formData) => submit(formData))}
    >
      <div className="text-deep-teal-800 flex flex-col gap-y-5 text-sm leading-5">
        <p className="font-semibold md:font-normal">Hi, {user?.firstName}!</p>
        {userPhoneNumber?.phoneNumber ? (
          <>
            <p>
              We are currently working on rolling out SMS notifications for important meetperry
              communications about investments, events, and general updates.
            </p>
            <p>This is the phone number we currently have on file. Is this correct?</p>
          </>
        ) : (
          <p>
            We are currently working on rolling out SMS notifications for important meetperry
            communications. Please enter your phone number below to never miss important
            communications from us.
          </p>
        )}
      </div>
      <PhoneField
        name="phoneNumber"
        label="Phone Number"
        disabled={isDisabled}
        control={control}
        error={errors.phoneNumber}
      />

      <input type="hidden" value={UserPhoneNumberConsent.CONSENTED} {...register('consent')} />

      <p className="text-deep-teal-300 text-sm leading-5">
        {userPhoneNumber?.phoneNumber
          ? `
          By submitting this form, you consent to receive SMS communications from meetperry. You can
          unsubscribe at any time. Your phone number is never shared with third parties.
          `
          : `          
          We use your phone number to contact you for key investment communications and to share
          important updates. We might also occasionally send you information about meetperry events
          and launches. By submitting this form, you consent to receive SMS communications from us.
          Your phone number is never shared with third parties.
          `}
      </p>
      <div className="flex grow flex-col justify-end gap-y-4">
        <Button uiType="primary" size="lg" type="submit" disabled={isDisabled}>
          Submit
        </Button>
        <Button
          uiType="ghost-secondary"
          size="lg"
          disabled={isDisabled}
          onClick={onDismissClickHandler}
        >
          Do not show this again
        </Button>
      </div>
    </form>
  )
}
