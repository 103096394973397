import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { Link } from '../../navigation'

interface BackButtonProps {
  href: string
  label: string
}

export function BackButton({ href, label }: BackButtonProps) {
  return (
    <Link className="flex items-center gap-2 font-medium text-orange-600 hover:text-orange-800" href={href}>
      <ArrowLeftIcon className="h-4 w-4" />
      <span>{label}</span>
    </Link>
  )
}
