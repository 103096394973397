import { DependencyList, MutableRefObject, useEffect } from 'react'

export const useScrollIntoView = <T extends Element>(
  ref: MutableRefObject<T>,
  deps?: DependencyList,
) => {
  useEffect(() => {
    if (ref?.current) {
      const rect = ref.current.getBoundingClientRect()
      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      if (!isVisible) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, deps)
}
