import { Children, cloneElement, FC, HTMLAttributes, isValidElement } from 'react'
import Link from 'next/link'
import { CheckIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

const Stepper: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => {
  const nonEmptyChildren = Children.toArray(children).filter(Boolean)
  const childrenWithProps = nonEmptyChildren.map((child, childIdx) => {
    const first = childIdx === 0
    const last = childIdx === nonEmptyChildren.length - 1

    // Checking isValidElement is the safe way
    // and avoids a typescript error too.
    if (isValidElement(child)) {
      return cloneElement<any>(child, { first, last })
    }
    return child
  })

  return (
    <nav className={clsx('flex items-center', className)} {...props}>
      {childrenWithProps}
    </nav>
  )
}

export default Stepper

export interface StepProps extends HTMLAttributes<HTMLDivElement> {
  readonly name: string
  readonly href: string
  readonly status: 'complete' | 'current' | 'upcoming'
}

export const Step: FC<StepProps> = ({ status, ...props }) => {
  const complete = status === 'complete'
  const current = status === 'current'
  const upcoming = status === 'upcoming'
  return (
    <>
      {complete && <CompleteStep {...props} />}
      {current && <CurrentStep {...props} />}
      {upcoming && <UpcomingStep {...props} />}
    </>
  )
}

export interface StepPropsWithoutStatus extends Omit<StepProps, 'status'> {
  readonly first?: boolean
  readonly last?: boolean
}

const CompleteStep: FC<StepPropsWithoutStatus> = ({
  name,
  href,
  first,
  last,
  className,
  ...props
}) => {
  return (
    <div data-testid="complete-step" className={clsx('flex flex-col', className)} {...props}>
      <div className="relative flex justify-center">
        {/* Left line */}
        {!first && (
          <div
            className="absolute top-[50%] bottom-[50%] left-0 right-[50%] flex items-center"
            data-testid="left-line"
          >
            <div className="bg-deep-teal-300 h-0.5 w-full" />
          </div>
        )}

        {/* Right line */}
        {!last && (
          <div
            className="absolute top-[50%] bottom-[50%] left-[50%] right-0 flex items-center"
            data-testid="right-line"
          >
            <div className="bg-deep-teal-300 h-0.5 w-full" />
          </div>
        )}

        <Link
          href={href}
          className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-orange-500"
        >
          <CheckIcon className="h-5 w-5 text-white" />
        </Link>
      </div>
      <label className="text-deep-teal-300 mt-3 self-center px-2 text-xs font-medium">{name}</label>
    </div>
  )
}

const CurrentStep: FC<StepPropsWithoutStatus> = ({
  name,
  href,
  first,
  last,
  className,
  ...props
}) => {
  return (
    <div data-testid="current-step" className={clsx('flex flex-col', className)} {...props}>
      <div className="relative flex justify-center">
        {/* Left line */}
        {!first && (
          <div
            className="absolute top-[50%] bottom-[50%] left-0 right-[50%] flex items-center"
            data-testid="left-line"
          >
            <div className="bg-deep-teal-300 h-0.5 w-full" />
          </div>
        )}

        {/* Right line */}
        {!last && (
          <div
            className="absolute top-[50%] bottom-[50%] left-[50%] right-0 flex items-center"
            data-testid="right-line"
          >
            <div className="bg-deep-teal-300 h-0.5 w-full" />
          </div>
        )}

        <Link
          href={href}
          className="relative inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-orange-500 bg-white"
        >
          <span className="h-2.5 w-2.5 rounded-full bg-orange-500" />
        </Link>
      </div>
      <label className="mt-3 self-center px-2 text-xs font-medium text-orange-600">{name}</label>
    </div>
  )
}

const UpcomingStep: FC<StepPropsWithoutStatus> = ({
  name,
  href,
  first,
  last,
  className,
  ...props
}) => {
  return (
    <div data-testid="upcoming-step" className={clsx('flex flex-col', className)} {...props}>
      <div className="relative flex justify-center">
        {/* Left line */}
        {!first && (
          <div
            className="absolute top-[50%] bottom-[50%] left-0 right-[50%] flex items-center"
            data-testid="left-line"
          >
            <div className="bg-deep-teal-300 h-0.5 w-full" />
          </div>
        )}

        {/* Right line */}
        {!last && (
          <div
            className="absolute top-[50%] bottom-[50%] left-[50%] right-0 flex items-center"
            data-testid="right-line"
          >
            <div className="bg-deep-teal-300 h-0.5 w-full" />
          </div>
        )}

        <Link
          href={href}
          className="border-deep-teal-300 group relative inline-flex h-8 w-8 items-center justify-center rounded-full border-2 bg-white"
        >
          <span className="group-hover:bg-deep-teal-300 h-2.5 w-2.5 rounded-full bg-transparent" />
        </Link>
      </div>
      <label className="text-deep-teal-300 mt-3 self-center px-2 text-xs font-medium">{name}</label>
    </div>
  )
}
