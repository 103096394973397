import { ComponentProps, FC } from 'react'
import clsx from 'clsx'

export const InvestmentCardLoading: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  return (
    <div
      className={clsx(
        'flex h-full animate-pulse flex-col gap-3 rounded-lg border border-slate-200 py-6 px-4',
        className,
      )}
      {...props}
    >
      <div className="flex items-start justify-between gap-3">
        <div className="h-11 w-11 shrink-0 grow-0 rounded bg-slate-200" />

        <div className="flex-1 space-y-1">
          <div className="h-5 w-[85%] bg-slate-200" />
          <div className="h-4 w-[40%] bg-slate-200" />
        </div>

        <div className="h-4 w-[10%] flex-shrink-0 shrink-0 grow-0 bg-slate-200" />
      </div>

      <div className="h-5 w-[40%] self-start rounded bg-slate-200" />
    </div>
  )
}
