import { useCallback } from 'react'
import { SchemaOf, ValidationError } from 'yup'

// The original source is here https://react-hook-form.com/advanced-usage/#CustomHookwithResolver
export const useYup = (validationSchema: SchemaOf<any>): ((...args: any[]) => any) =>
  useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (e) {
        const errors = e as ValidationError
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path!]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {},
          ),
        }
      }
    },
    [validationSchema],
  )
