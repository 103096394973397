import { ComponentProps, FC } from 'react'
import Link from 'next/link'
import { UserGroupIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

const GroupsEmptyState: FC<ComponentProps<'div'>> = ({ className, ...props }) => (
  <div className={clsx('items-top flex gap-3', className)} {...props}>
    <UserGroupIcon role="img" className="text-deep-gray-100 h-6 w-6 shrink-0" />

    <div className="flex-col text-left">
      <h3 className="text-deep-teal-900 basic-text-sm leading-1 font-semibold">
        You&apos;re not part of any group
      </h3>
      <p className="text-deep-teal-400 text-sm font-normal leading-5">
        To see a list of groups{' '}
        <Link href="/groups" className="text-orange-600 hover:text-orange-800">
          click&nbsp;here
        </Link>
        .
      </p>
    </div>
  </div>
)

export default GroupsEmptyState
