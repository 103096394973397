import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'
import { Link, useRouter } from 'ui/components/navigation'

export const NavTabs: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children }) => {
  return (
    <nav className={clsx(className, 'border-deep-teal-100 -mb-px flex border-b')} aria-label="Tabs">
      {children}
    </nav>
  )
}
/**
 * Navigation Tab function component
 * @param title - title of the tab
 * @param path - full relative path to the tab content page (from root)
 * @constructor
 */
export const NavTab: FC<{
  className?: string
  title: string
  path: string
}> = ({ className, title, path }) => {
  const router = useRouter()
  const selected = router.pathname === path
  return (
    <Link
      href={path}
      routerDirection="root"
      className={clsx(
        selected
          ? 'border-orange-500 font-bold text-orange-600'
          : 'text-deep-teal-500 hover:text-deep-teal-500 hover:border-deep-teal-200 border-transparent font-medium',
        'cursor-pointer whitespace-nowrap border-b-2 py-4 text-center text-sm font-medium',
        className,
      )}
      role="link"
      aria-current={selected ? 'page' : undefined}
    >
      {title}
    </Link>
  )
}
