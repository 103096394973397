import { omit } from 'lodash'
import { useRoutingContext } from '../../lib/navigation'
import { RoutingContextValue } from '../../lib/navigation/RoutingContext'

export type { RouterEvents } from '../../lib/navigation'

export const useRouter = (): RouterContext => {
  return omit(useRoutingContext(), ['LinkComponent'])
}

export type RouterContext = Omit<RoutingContextValue, 'LinkComponent'>
