import { ComponentProps, FC, Fragment, PropsWithChildren } from 'react'
import { AmityCommunity } from 'amity/src/api/types'
import clsx from 'clsx'
import { GroupCard, GroupCardLoading } from 'ui/components/content'
import GroupsEmptyState from './GroupsEmptyState'

type GroupsProps = Pick<ComponentProps<'div'>, 'className'> & {
  groups: AmityCommunity[]
  isLoading: boolean
}

const GroupCardDivider: FC = () => <div className="bg-deep-teal-100 h-px last-of-type:hidden" />

const GroupCardLoadingDivider: FC = () => <div className="h-px animate-pulse bg-slate-200" />

const GroupsContainer: FC<PropsWithChildren<Pick<ComponentProps<'div'>, 'className'>>> = ({
  className,
  children,
}) => <div className={clsx('flex w-full flex-col gap-4 py-2', className)}>{children}</div>

export const GroupsList: FC<GroupsProps> = ({ groups, isLoading, className }) => {
  if (isLoading) {
    return (
      <GroupsContainer className={className}>
        <GroupCardLoading />
        <GroupCardLoadingDivider />
        <GroupCardLoading />
        <GroupCardLoadingDivider />
        <GroupCardLoading />
        <GroupCardLoadingDivider />
        <GroupCardLoading />
      </GroupsContainer>
    )
  }

  if (!groups?.length) {
    return (
      <GroupsContainer className={className}>
        <GroupsEmptyState />
      </GroupsContainer>
    )
  }

  return (
    <GroupsContainer className={className}>
      {groups.map((group) => (
        <Fragment key={group.communityId}>
          <GroupCard group={group} />
          <GroupCardDivider />
        </Fragment>
      ))}
    </GroupsContainer>
  )
}
