import { Context, useContext } from 'react'
import { GeolocationContext, GeolocationService } from './GeolocationContext'

export const useGeolocationContext = () => {
  const context = useContext<GeolocationService>(
    GeolocationContext as unknown as Context<GeolocationService>,
  )
  if (!context) {
    throw new Error('useGeolocationContext must be used under GeolocationContextProvider')
  }
  return context
}
