import { FC, useEffect, useState } from 'react'
import { useSession } from 'auth'
import { LDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk'

export interface ApplicationContext {
  key: 'iOS' | 'Android' | 'Unknown'
  ciBuildNumber: string
  name: string
  id: string
  build: string
  version: string
}
export interface LDContext {
  appType: {
    key: 'web' | 'mobile'
    name: string
  }
  application?: ApplicationContext
}

interface LaunchDarklyProviderProps {
  children: JSX.Element
  specificContext: LDContext
}

export const LaunchDarklyProvider: FC<LaunchDarklyProviderProps> = ({
  children,
  specificContext,
}) => {
  const { user, sa } = useSession()
  const [ldContext, setLDContext] = useState<ProviderConfig['context']>()

  useEffect(() => {
    if (user) {
      setLDContext({
        kind: 'multi',
        user: {
          key: user.id.toString(),
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          firstName: user.firstName,
          lastName: user.lastName,
          custom: {
            roles: user.roles || [],
            sa: sa || [],
          },
        },
        ...specificContext,
      })
    }
  }, [user])

  return (
    <LDProvider
      clientSideID={process.env.NEXT_PUBLIC_LD_CLIENT_SIDE_ID!}
      reactOptions={{ useCamelCaseFlagKeys: false }}
      deferInitialization={true}
      context={ldContext}
    >
      {children}
    </LDProvider>
  )
}
