import { FC, Fragment } from 'react'
import { Nominator } from 'api/dto'
import { NominatorElement } from './NominatorElement'

export const NominatorsList: FC<{ nominators: Nominator[] }> = ({ nominators }) => {
  return (
    <div className="flex flex-col">
      {nominators.map((nominator, index) => (
        <Fragment key={nominator.userId}>
          <NominatorElement nominator={nominator} rank={index + 1} />
          <div className="bg-deep-teal-100 my-2 h-px last-of-type:hidden" />
        </Fragment>
      ))}
    </div>
  )
}
