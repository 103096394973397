import { FC } from 'react'
import Head from 'next/head'

export const Metadata: FC<{ title?: string; description?: string }> = ({ title, description }) => {
  return (
    <Head>
      {title && <title key="title">{title}</title>}
      {title && <meta property="og:title" content={title} key="ogTitle" />}
      {description && <meta name="description" content={description} key="description" />}
      {description && <meta property="og:description" content={description} key="ogDescription" />}
    </Head>
  )
}

export default Metadata
