import { axios } from 'api/lib'
import { AxiosRequestConfig } from 'axios'
import { AmityFile } from './types'

const BASE_URL = process.env.NEXT_PUBLIC_AMITY_API_URL

export async function uploadImageFile(
  file: File,
  axiosConfig: AxiosRequestConfig = {},
): Promise<AmityFile> {
  const formData = new FormData()
  formData.append('files', file)

  const { data } = await axios.post(`/v4/images`, formData, { ...axiosConfig, baseURL: BASE_URL })
  return data[0]
}

export function getFileDownloadUrl(fileId: string): string {
  return `${BASE_URL}/v3/files/${fileId}/download`
}
