import { FC } from 'react'
import { AmityAvatar } from '../Avatar'

export const GroupAvatar: FC<{ fileId?: string; className?: string; displayName: string }> = ({
  fileId,
  displayName,
  className,
}) => {
  return (
    <div className={className}>
      <AmityAvatar
        className="h-full w-full"
        fileId={fileId}
        fallback={<GroupAvatarDefault displayName={displayName} />}
      />
    </div>
  )
}

const GroupAvatarDefault: FC<{ displayName: string }> = ({ displayName }) => {
  return (
    <span className="bg-deep-teal-500 flex h-full w-full flex-shrink-0 items-center justify-center rounded-full">
      <span
        data-testid="group-default-avatar"
        className="font-brand text-taupe-500 font-normal uppercase lining-nums leading-none"
      >
        {displayName && getAbbreviation(displayName)}
      </span>
    </span>
  )
}

const getAbbreviation = (groupName: string) =>
  groupName
    .split(' ')
    .map((word) => word[0])
    .join('')
