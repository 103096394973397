import { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { Combobox } from '@headlessui/react'
import { MapPinIcon } from '@heroicons/react/24/solid'
import {
  GeocodeFeature,
  GeocodeResponse,
  GeocodeService,
} from '@mapbox/mapbox-sdk/services/geocoding'
import clsx from 'clsx'
import { geocoding } from 'ui/src/lib/mapbox'

const getCityName = (city: GeocodeFeature): string => city.text
const getCountryName = (city: GeocodeFeature): string =>
  city.context && city.context[city.context.length - 1]?.text
const getRegionName = (city: GeocodeFeature): string => {
  if (!city.context?.length) return ''
  const region = city.context.find((feature) => feature.id.split('.')[0] === 'region')
  return region?.text!
}

const createDisplayValue = ({ city, region, country }: City): string => {
  return [city, region, country].filter(Boolean).join(', ')
}

const areCityValuesEquivalent = (cityA?: City, cityB?: City): boolean => {
  if (cityA === cityB) return true
  if (
    cityA?.city === cityB?.city &&
    cityA?.region === cityB?.region &&
    cityA?.country === cityB?.country
  )
    return true
  return false
}

interface CityInputProps {
  className?: string
  onChange?: (value: City | null) => void
  value?: City
  name?: string
  placeholder?: string
}

interface City {
  city: string
  region?: string
  country: string
  geocode?: GeocodeFeature
}

// eslint-disable-next-line react/display-name
export const CityInput: FC<CityInputProps> = ({
  value,
  onChange,
  name,
  className,
  placeholder,
}) => {
  const [cities, setCities] = useState<City[]>([])
  const [selected, setSelected] = useState<City>(value!)
  const [search, setSearch] = useState('')
  const [geocodingService, setGeocodingService] = useState<GeocodeService>()

  useEffect(() => {
    if (areCityValuesEquivalent(selected, value)) return
    setSelected(value!)
  }, [value])

  useEffect(() => {
    if (areCityValuesEquivalent(selected, value)) return

    onChange && onChange(selected)
  }, [selected])

  useEffect(() => setGeocodingService(geocoding()), [])

  useEffect(() => {
    if (search.length > 1 && (geocoding as any)) {
      geocodingService
        ?.forwardGeocode({
          query: search,
          types: ['place'],
          language: ['en'],
        })
        .send()
        .then(({ body: geocodeResponse }: { body: GeocodeResponse }) =>
          setCities(
            geocodeResponse.features.map((feature) => ({
              city: getCityName(feature),
              region: getRegionName(feature),
              country: getCountryName(feature),
              geocode: feature,
            })),
          ),
        )
    }
  }, [search, geocoding])

  const onSearchChangeHandle: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value)
  }

  const onSelectedChangeHandler = (value: City) => {
    setSelected(value || undefined)
    setSearch('')
  }

  const thereAreSearchResults = cities.length > 0 && search?.length > 1
  const thereIsNoResult = cities.length === 0 && search?.length > 1

  return (
    <Combobox
      as="div"
      className="relative"
      data-testid="city-input-combobox"
      value={selected || null}
      onChange={onSelectedChangeHandler}
      nullable
    >
      <Combobox.Input
        name={name}
        // disable autocomplete for chrome
        autoComplete={`new-${name}`}
        className={className}
        onChange={onSearchChangeHandle}
        displayValue={(value: City) => (value ? createDisplayValue(value) : '')}
        defaultValue={'' as any}
        placeholder={placeholder}
      />
      {(thereAreSearchResults || thereIsNoResult) && (
        <Combobox.Options className="ring-deep-teal-900 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm">
          {cities.map((cityItem) => (
            <Combobox.Option
              key={cityItem.geocode?.id}
              value={cityItem}
              className={({ active }) =>
                clsx(
                  'text-deep-teal-900 relative flex cursor-default select-none flex-wrap py-2 pl-3 pr-9',
                  active ? 'bg-taupe-300' : 'text-deep-teal-900',
                )
              }
            >
              {({ active }) => (
                <>
                  <MapPinIcon
                    className={clsx('h-5 w-5 ', active ? 'text-taupe-700' : 'text-taupe-600')}
                  />
                  <span className="px-4 font-semibold">{cityItem.city}</span>
                  <span>
                    {cityItem.region ? `${cityItem.region}, ${cityItem.country}` : cityItem.country}
                  </span>
                </>
              )}
            </Combobox.Option>
          ))}
          {thereIsNoResult && (
            <>
              <div className="text-deep-teal-900 pl-4 font-semibold">Nothing has been found</div>
              <div className="text-deep-teal-400 pl-4 pt-2">Try to use another term</div>
            </>
          )}
        </Combobox.Options>
      )}
    </Combobox>
  )
}
