import { Event } from 'api/cms'
import { zonedTimeToUtc } from 'date-fns-tz'

type EventDateInfo = Partial<
  Pick<Event, 'type' | 'eventDate' | 'displayDate' | 'displayTime' | 'displayTimezone'>
>

export interface FormattedEventDate {
  shortDay: string
  time: string
  timezone: string
  full: string
}

export const eventDateFormatter = (eventDateInfo: EventDateInfo): FormattedEventDate => {
  const { type, eventDate, displayDate, displayTime, displayTimezone } = eventDateInfo

  if (type === 'inperson' && displayDate && displayTime && displayTimezone) {
    // Date in the local time zone
    const date = new Date(`${displayDate} ${displayTime}`) // parse(`${displayDate} ${displayTime}`, 'yyyy-MM-dd HH:mm:ss', new Date())
    // Date converted to the UTC time zone
    const utcDate = zonedTimeToUtc(date, displayTimezone)

    return formatDateTimeAndParts(utcDate, displayTimezone)
  }

  // virtual and fallback to events with no display date fields
  if (eventDate) {
    return formatDateTimeAndParts(new Date(eventDate))
  }

  return {
    shortDay: '',
    time: '',
    timezone: '',
    full: '',
  }
}

const getLanguage = () => {
  if (navigator.languages) {
    return navigator.languages[0]
  }
  return navigator.language
}

const formatDateTimeAndParts = (date: Date, timezone?: string): FormattedEventDate => {
  const timeZone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  const isNotCurrentYear = date.getFullYear() !== new Date().getFullYear()

  //configure formatter to display date in the specified timezone
  const dateTimeFormatter = new Intl.DateTimeFormat(getLanguage(), {
    weekday: 'long',
    year: isNotCurrentYear ? 'numeric' : undefined,
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h12',
    timeZone,
    timeZoneName: 'short',
  })

  const shortDayFormatter = new Intl.DateTimeFormat(getLanguage(), {
    month: 'short',
    day: 'numeric',
    timeZone,
  })

  const timeFormatter = new Intl.DateTimeFormat(getLanguage(), {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h12',
    timeZone,
  })

  return {
    shortDay: shortDayFormatter.format(date),
    time: timeFormatter.format(date),
    timezone: dateTimeFormatter
      .formatToParts(date)
      .filter((part) => part.type === 'timeZoneName')
      .map((part) => part.value)
      .join(' '),
    full: dateTimeFormatter.format(date),
  }
}
