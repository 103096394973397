import { ComponentProps, FC, ReactNode, useState } from 'react'
import Image from 'next/image'
import { UserIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

export interface AvatarProps extends ComponentProps<'div'> {
  src?: string | null
  fallback?: ReactNode
}

const DefaultAvatar: ReactNode = (
  <UserIcon
    className="text-deep-teal-500 mb-[-10%] ml-[-5%] h-[110%] w-[110%]"
    data-testid="default-avatar"
  />
)

const Avatar: FC<AvatarProps> = ({ className, src, fallback = DefaultAvatar }) => {
  const [imgError, setImgError] = useState(false)

  return (
    <div className={clsx('bg-taupe-500 overflow-hidden rounded-full', className)}>
      {src && !imgError ? (
        <Image
          className="h-full w-full"
          src={src}
          data-testid="avatar"
          onError={() => {
            setImgError(true)
          }}
          width={100}
          height={100}
          alt={''}
        />
      ) : (
        <>{fallback}</>
      )}
    </div>
  )
}

export default Avatar
