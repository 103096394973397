import { ChangeEventHandler, FC } from 'react'
import { FieldError } from 'react-hook-form'
import clsx from 'clsx'

export interface AvatarFile {
  id?: string
  name?: string
  size?: number
  mimeType?: string
  url: string
  sourceFile?: File
}

export interface AvatarInputProps {
  readonly className?: string
  readonly name: string
  readonly onChange?: (value: AvatarFile) => void
  readonly disabled?: boolean
  readonly placeholder?: string
  readonly error?: Pick<FieldError, 'message'>
}

export const AvatarInput: FC<AvatarInputProps> = ({
  name,
  placeholder,
  disabled,
  onChange,
  error,
  className,
}) => {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const fileList = e.target.files
    const file = fileList && fileList[0]
    if (file && onChange) {
      onChange({
        url: URL.createObjectURL(file),
        size: file.size,
        name: file.name,
        mimeType: file.type,
        sourceFile: file,
      })
    }
  }

  const fakeUploadButtonClassName = clsx(
    'block w-fit', // Position
    'border py-1.5 px-2 border-taupe-600 rounded', // Border
    'text-deep-teal-800 text-xs leading-3 font-medium', // Text
    'hover:bg-deep-teal-50 hover:border-deep-teal-200 focus:bg-deep-teal-100 focus:border-deep-teal-300',
    error && 'border-pure-red',
    className,
  )

  return (
    <div className="flex flex-col content-start">
      <div className={fakeUploadButtonClassName}>
        <span>Change</span>
        <input
          id={name}
          name={name}
          disabled={disabled}
          type="file"
          accept=".png,.jpg,.jpeg"
          className="hidden"
          onChange={handleOnChange}
          value={''}
          data-testid="avatar-upload"
        />
      </div>
      {placeholder && (
        <span
          data-testid="avatar-upload-placeholder"
          className="text-deep-teal-400 mt-1 text-xs font-normal leading-4"
        >
          {placeholder}
        </span>
      )}
    </div>
  )
}
