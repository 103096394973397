import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

const Error: FC<HTMLAttributes<HTMLParagraphElement>> = ({ children, className, ...props }) => {
  return (
    <p className={clsx('text-sm leading-4 text-deep-teal-800', className)} {...props}>
      {children}
    </p>
  )
}

export default Error
