import { FC, HTMLAttributes, ReactNode } from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import clsx from 'clsx'

export interface DialogProps {
  title?: string | ReactNode
  actions?: ReactNode
  open?: boolean
  onClose: (value?: boolean) => void
  containerClassName?: string
  overlayClassName?: string
  children: ReactNode
}

export const Dialog: FC<Omit<HTMLAttributes<HTMLDivElement>, 'title'> & DialogProps> = ({
  open,
  onClose,
  children,
  className,
  title,
  actions,
  containerClassName,
  overlayClassName,
  ...props
}) => {
  return (
    <Transition show={open} as="div">
      <HeadlessDialog open={open} onClose={onClose} className="fixed z-10 inset-0 overflow-y-auto">
        <div
          className={clsx(
            'flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center',
            containerClassName,
          )}
        >
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessDialog.Overlay
              className={clsx(
                'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity',
                overlayClassName,
              )}
            />
          </Transition.Child>

          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="flex justify-center"
          >
            <div
              className={clsx(
                'flex flex-col align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-full md:max-w-md sm:w-full sm:p-6',
                className,
              )}
              {...props}
            >
              <HeadlessDialog.Title
                as="h1"
                className="mb-4 text-center text-3xl leading-6 font-medium text-gray-900"
              >
                {title}
              </HeadlessDialog.Title>
              {children}
              {actions && <div className="mt-5 sm:mt-4 flex flex-row self-end">{actions}</div>}
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}
