import { FC } from 'react'
import {
  GeolocationService,
  GeolocationContextProvider,
  GeolocationResult,
} from 'ui/lib/geolocation'

const GeolocationServiceImplementation: GeolocationService = {
  getCurrentPosition: () => {
    if (!navigator.geolocation?.getCurrentPosition) {
      return Promise.reject(new Error('Geolocation is not supported by your browser'))
    }

    return new Promise<GeolocationResult>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          }),
        reject,
      )
    })
  },
}

export const GeolocationProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  return (
    <GeolocationContextProvider value={GeolocationServiceImplementation}>
      {children}
    </GeolocationContextProvider>
  )
}
